import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Switch, Route, Link } from 'react-router-dom';

import InputTest from './input';
import ButtonTest from './button';
import PopoverTest from './popover';
import DropDownTest from './dropdown/DropDownTest';
import TabsTest from './tabs/TabsTest';
import './style.scss';

@observer
export default class Test extends React.Component {

	render() {
		return <div className="test">
			<div className="test-menu">
				<Link to="/test">Button</Link>
				<Link to="/test/input">Input</Link>
				<Link to="/test/popover">Popover</Link>
				<Link to="/test/dropdown">DropDown</Link>
				<Link to="/test/tabs">Tabs</Link>
			</div>
			<div className="test-content">
				<Route path="/test" exact><ButtonTest /></Route>
				<Route path="/test/input"><InputTest /></Route>
				<Route path="/test/popover"><PopoverTest /></Route>
				<Route path="/test/dropdown"><DropDownTest /></Route>
				<Route path="/test/tabs"><TabsTest /></Route>
			</div>
		</div>;
	}

}
