import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, ButtonGroup } from '@smartplatform/ui';

@observer
export default class ButtonTest extends React.Component {

	@observable group = 1;

	render() {
		return <>
			<div className="block">
				<Button>Default</Button>
				<Button variant="primary">Primary</Button>
				<Button variant="success">Success</Button>
				<Button variant="danger">Danger</Button>
				<Button variant="warning">Warning</Button>
			</div>
			<div className="block">
				<Button size="sm">Small</Button>
				<Button>Default</Button>
				<Button size="lg">Large</Button>
			</div>
			<div className="block">
				<ButtonGroup>
					<Button onClick={() => this.group = 1} variant={this.group === 1 ? 'info' : 'primary'}>One</Button>
					<Button onClick={() => this.group = 2} variant={this.group === 2 ? 'info' : 'primary'}>Two</Button>
					<Button onClick={() => this.group = 3} variant={this.group === 3 ? 'info' : 'primary'}>Three</Button>
				</ButtonGroup>
			</div>
		</>;
	}

}
