import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Popup, Row, Form, Field, MaskedInput } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

const DEFAULT_PASSWORD = '123';

const hashCode = function(str) {
	let hash = 0, i, chr;
	if (str.length === 0) return hash;
	for (i = 0; i < str.length; i++) {
		chr   = str.charCodeAt(i);
		hash  = ((hash << 5) - hash) + chr;
		hash |= 0;
	}
	return hash;
};

const FIELDS = [
	'lastName',
	'firstName',
	'middleName',
	'username',
];

@observer
export default class NewUser extends React.Component {

	@observable error = null;
	@observable isSaving = false;

	constructor(props) {
		super(props);
		this.user = new store.model.User();
		this.user.password = DEFAULT_PASSWORD;
	}

	beforeSave = user => {
		this.error = null;

		const allChars = FIELDS.map(field => (user[field] || '').trim()).join('');
		// console.log('allChars', allChars);

		if (allChars.length === 0) {
			this.error = t('contact.emptyName');
			return false;
		}

		if (!user.email) {
			user.email = `erp-${hashCode(allChars)}@example.com`;
		}

		if (user.phone) {
			user.phone = user.phone.replace(/[^+\d]/g, '');
		}
	};

	onSave = async (user) => {
		this.isSaving = true;

		const allChars = FIELDS.map(field => (user[field] || '').trim()).join('');
		if (user.email === `erp-${hashCode(allChars)}@example.com`) {
			user.email = `erp-${user.id}@example.com`;
			await user.save();
		}
		await this.props.order.client.contacts.add(user.id);
		await this.props.order.contacts.add(user.id);
		this.props.onClose(true);
	};

	onClose = () => {
		this.props.onClose();
	};

	onError = (error) => {
		console.log(error,'form error')
		if (error && error.message && error.message.includes('Email already exists')) this.error = t('user.emailAlreadyExists');
	};

	render() {
		const controls = <span className="error" style={{ marginLeft: 15 }}>{this.error}</span>;

		return <Popup width={600} onClose={this.onClose} header={t('contact.new')}>
			{!this.isSaving &&
				<Form onError={this.onError} record={this.user} beforeSave={this.beforeSave} stay onSave={this.onSave} controls={controls}>
					<Row>
						<Field property="lastName" label={t('user.lastName')} />
						<Field property="firstName" label={t('user.firstName')} />
						<Field property="middleName" label={t('user.middleName')} />
					</Row>
					<Row>
						<Field property="username" label={t('user.name')} />
						<Field property="email" label={t('user.email')} />
						<div/>
					</Row>
					<Row>
						<Field property="phone" label={t('user.phone')}>
							<MaskedInput mask="+7 (999) 999 9999" placeholder="Телефон" />
						</Field>
						<div />
						<div />
					</Row>
				</Form>
			}
		</Popup>;
	}
	
}
