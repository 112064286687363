import React from 'react';
import { ColorPicker, Field, RecordSelect } from '@smartplatform/ui';
import { DeleteButton, ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';
import { observable } from "mobx";
import { observer } from "mobx-react";
import AddIcon from '!!svg-react-loader!client/img/add-btn.svg';

@observer
export default class Edit extends React.Component {
    @observable record = null;
    @observable roleNames = [];

    constructor(props) {
        super(props);
        this.init();
    };

    init = async () => {
        // чтобы скрыть кнопку "добавить",если ролей не осталось
        const count = await store.model.Role.count();
        this.rolesCount = (count > 1) ? count - 1 : 1;
    };

    getRecord = async record => {
        this.record = record;
        if (!record.roleNames) {
            this.record.roleNames = [];
        }
        this.roleNames = this.record.roleNames;
    };

    onValueChange = (e, i) => this.record.roleNames[i] = e.name;

    deleteValue = i => this.record.roleNames.splice(i, 1);

    addValue = () => this.record.roleNames.push('');

    beforeSave = () => {
        this.record.roleNames = this.record.roleNames.filter(role => role.length > 0)
    }

    render() {
        const {modelName, path, match} = this.props;
        const id = parseInt(match.params.id);
        return <ModelEdit model={store.model[modelName]} getRecord={this.getRecord} path={path} id={id}
                          beforeSave={this.beforeSave}
                          title={t('orderList.title')}>
            <Field property="name" label={t('name')}/>
            <Field property="color" label={t('color')}><ColorPicker
            /></Field>
            <Field property="priority" label={t('priority')}/>
            <Field property="default" label={t('orderList.default')}/>
            <Field property="closing" label={t('orderList.closing')}/>
            <Field property="description" label={t('description')}><textarea rows={5}/></Field>
            <div>
                <div className="table-list">
                    <div className="form-field">
                        <label>{t('orderList.roleNames')}: </label>
                        {(this.roleNames.length > 0) && <table>
                            <thead>
                            <tr>
                                <th>{t('number')}</th>
                                <th>{t('orderList.role')}</th>
                                <th/>
                            </tr>
                            </thead>
                            <tbody>
                            {this.roleNames.map((value, i, values) => <tr key={i}>
                                < td width={50}>{i + 1}</td>
                                <td>
                                    <RecordSelect
                                        width={150}
                                        model={store.model.Role}
                                        filter={{where: {name: {nin: ['admin', ...values]}}}}
                                        property='name'
                                        showValue={value}
                                        onChange={e => this.onValueChange(e, i)}
                                    />
                                </td>
                                <td className="actions">
                                    <DeleteButton onConfirm={() => this.deleteValue(i)}/>
                                </td>
                            </tr>)}
                            </tbody>
                        </table>}
                        {(this.rolesCount !== this.roleNames.length) &&
                        <div className="add-row"><AddIcon onClick={this.addValue} className="add-btn">add</AddIcon>
                        </div>}
                    </div>
                </div>
            </div>
        </ModelEdit>;
    }
}
