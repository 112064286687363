import React from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Form } from '@smartplatform/ui';
import store from 'client/store';

@withRouter @observer
export default class Edit extends React.Component {
	
	static propTypes = {
		model: PropTypes.any,
		title: PropTypes.any,
		path: PropTypes.string,
		returnTo: PropTypes.string,
		getRecord: PropTypes.func,
		beforeSave: PropTypes.func,
		onSave: PropTypes.func,
		stay: PropTypes.bool,
		noDelete: PropTypes.bool,
		disabled: PropTypes.bool,
		filter: PropTypes.object,
	};

	@observable record = null;
	@observable error = null;
	@observable isLoading = true;

	isNew = false;
	
	constructor (props) {
		super(props);
		if (props.title) store.ui.title = props.title;
		this.init();
	}

	@action init = async () => {
		this.isLoading = true;
		if (this.props.id) {
			this.error = null;
			try {
				this.record = await this.props.model.findById(this.props.id, this.props.filter);
				this.props.getRecord && this.props.getRecord(this.record);
			} catch (e) {
				this.error = e.message;
			}
		} else {
			const { id, ...rest } = this.props.match.params;
			this.record = new this.props.model(rest);
			this.props.getRecord && this.props.getRecord(this.record);
			this.isNew = true;
		}
		this.isLoading = false;
	};
	
	onError = error => {
		console.log('onError', error);
	};
	
	onSave = async (record) => {
		if (this.props.onSave) await this.props.onSave(record);
		if (this.isNew && this.props.stay) {
			this.isNew = false;
			store.route.push({ path: `${this.props.path}/${record.id}` });
		}
		else {
			this.back();
		}
	};
	
	back = () => {
		store.route.push({ path: this.props.returnTo || this.props.path });
	};
	
	render () {
		if (this.isLoading) return '...';
		if (this.error) return <div className="error">{this.error}</div>;

		const { children, beforeSave, noDelete, disabled } = this.props;

		const props = {
			record: this.record,
			stay: true,
			onDelete: this.back,
			noDelete,
			disabled,
			onSave: this.onSave,
			onError: this.onError,
			beforeSave,
		};

		return !this.isLoading && <div className="fixed-page">
			<Form {...props}>{children}</Form>
			{/*back: {this.makeRootPath(this.record)}*/}
		</div>;
	}
}
