import { Column } from '@smartplatform/ui';
import store from 'client/store';
import { ModelList } from 'components';
import t from 'i18n';
import React from 'react';


export default ({ modelName, path }) => {
	const renderMaterialsCount = (record) => record.length;
	return <ModelList model={store.model[modelName]} path={path} filter={{include:['materials']}}>
		<Column property="name" label={t('name')}  />
		<Column relation="materials" computed={renderMaterialsCount}  label={t('material.count')}/>
	</ModelList>;
};
