import React, { useState } from 'react';

import { Row, Field, NumberInput, RelationSelect } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import { fio } from 'client/tools';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, match, title, type }) => {
	const [ personFilter, setPersonFilter ] = useState(undefined);
	
	const id = parseInt(match.params.paymentId);
	const budgetId = parseInt(match.params.budgetId);
	
	const budgetFilter = budgetId ? { where: { budgetId }} : undefined;
	
	(async () => {
		if (budgetId) {
			const roles = await store.model.BudgetRole.find({
				where: { budgetId },
				fields: ['id', 'personId'],
			});
			if (roles.length > 0) setPersonFilter({
				where: {
					id: { inq: roles.map(role => role.personId) },
				}
			});
		}
	})();
	
	return <ModelEdit model={store.model[modelName]} path={path} id={id} title={title}>
		{type === 'outgoing' && <Row>
			<Field relation="person" computed={fio} property='lastName' label={t('person.title')} filter={personFilter}/>
			<Field relation="budget" property="name" label={t('budget.title')} disabled={!!budgetId}/>
			<Field relation="role" property="name" label={t('budgetRole.role')} filter={budgetFilter}/>
		</Row>}
		<Row>
			<Field property="sum" label={t('sum')}><NumberInput type="currency" /></Field>
			{type === 'incoming' && <Field relation="budget" property="name" label={t('budget.title')} disabled={!!budgetId}/>}
			<Field property="date" label={t('payment.date')}/>
			{type === 'outgoing' && <div/>}
		</Row>
		<Field property='comment' label={t('comment.title')}><textarea rows={5}/></Field>
	</ModelEdit>;
};
