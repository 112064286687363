import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Field } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';
import t from 'i18n';


@withRouter @observer
export default class Edit extends React.Component {

	@observable record = null;

	getRecord = record => {
		this.record = record;
	};

	render() {
		const { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);

		return <ModelEdit model={store.model[modelName]} stay path={path} id={id} getRecord={this.getRecord} title={t('priceCategory.title')}>
			<Field property="name" label={t('name')}/>
		</ModelEdit>;
	}
};
