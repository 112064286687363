import React from 'react';

import { Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

const filter = {
	include: [
		{
			relation: 'priceCategoryFeatures',
			scope: {
				include: [
					{ relation: 'feature', scope: { fields: ['id', 'name'] }},
				],
				order: 'weight asc',
			}
		},
		{
			relation: 'positions',
			scope: {
				fields: ['id'],
			}
		}
	],
};

const renderFeatures = record => record.priceCategoryFeatures().filter(c => !!c.feature).map(c => c.feature.name).join(', ');

const renderPositions = records => records.length;

export default ({ modelName, path }) => {
	return <ModelList model={store.model[modelName]} path={path} title={t('priceList.title')} filter={filter} order="name asc">
		<Column property="name" label={t('name')} />
		<Column computed={renderFeatures} label={t('feature.plural')} />
		<Column width={100} relation="positions" computed={renderPositions} label={t('priceList.positions')} />
	</ModelList>;
};
