import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, Checkbox, RecordSelect } from '@smartplatform/ui';
import { fio } from 'client/tools';
import { UserCard } from 'components';
import NewUser from './NewUser';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

const NEW_CONTACT = 'NEW_CONTACT';

@observer
export default class Contacts extends React.Component {

	@observable available = [];
	@observable contacts = [];
	@observable newContact = null;
	@observable newUser = false;
	@observable onlyAvailable = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.available = await this.props.order.client.contacts.find({
			fields: ['id'],
			order: 'id asc',
		});

		this.contacts = await this.props.order.contacts.find({
			fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'phone', 'email', 'avatar'],
			order: 'id asc',
		});
	};

	onNewContactChange = user => {
		if (user === NEW_CONTACT) {
			console.log(NEW_CONTACT);
			this.newUser = true;
		}
		else {
			this.newContact = user;
		}
	};

	onClose = async (updated) => {
		this.newUser = false;
		if (updated) await this.init();
	};

	deleteContact = async (user) => {
		await this.props.order.contacts.remove(user.id);
		await this.init();
	};

	addContact = async () => {
		// добавление контакта к контрагенту,если его нет в списке
		// const existsInClient = this.available.find(contact => contact.id === this.newContact.id)
		// if (!existsInClient) await this.props.order.client.contacts.add(this.newContact.id)
		await this.props.order.contacts.add(this.newContact.id);
		this.newContact = null;
		await this.init();
	};

	onShowAllUsersChange = (value) => this.onlyAvailable = value;

	render() {
		const filter = { where: { and: [] } };
		if (this.onlyAvailable && (this.available.length > 0)) filter.where.and.push({ id: { inq: this.available.map(c => c.id) } });
		if (this.contacts.length > 0) filter.where.and.push({ id: { nin: this.contacts.map(c => c.id) } });
		if (filter.where.and.length === 0) filter.where = { id: -1 };

		const appendItems = store.model.User.INFO.WRITE ?
			[{ label: t('contact.new'), value: NEW_CONTACT, className: 'new-user' }]
			:
			undefined;

		return <div className="order-contacts">
			<div className="form-field">
				<label>{t('client.contacts')}</label>
				{this.contacts.map(user => {
					return <UserCard key={user.id} user={user} noDefaultEmail onRemove={this.deleteContact} confirmMessage={t('order.removeContact')} />
				})}
			</div>

			<div className="new-contact">
				<Checkbox onChange={this.onShowAllUsersChange} checked={this.onlyAvailable} className='label' label={t('client.onlyAvailable')} className='checkbox' />
				<RecordSelect
					model={store.model.User}
					computed={fio}
					value={this.newContact}
					onChange={this.onNewContactChange}
					searchPlaceholder={t('contact.searchHint')}
					itemsPerPage={8}
					className="with-new-record"
					filter={filter}
					emptyContent={t('client.noContacts')}
					appendItems={appendItems}
					isRequired
				/>

				<Button onClick={this.addContact} variant="primary" disabled={!this.newContact}>{t('client.addContact')}</Button>
			</div>
			{this.newUser && <NewUser onClose={this.onClose} order={this.props.order} />}
		</div>;
	}

}
