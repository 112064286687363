import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { Button } from '@smartplatform/ui';
import { formatTime } from './tools';
import t from 'i18n';
import store from './store';
import logo from './img/sp-logo.svg';

@observer
export default class Login extends React.Component {
	
	@observable login = '';
	@observable password = '';
	@observable captcha = '';
	@observable error = null;
	@observable tries = null;
	@observable reloads = 0;
	
	constructor(props) {
		super(props);
	}
	
	onLoginChange = (e) => {
		this.login = e.target.value;
	};
	
	onPasswordChange = (e) => {
		this.password = e.target.value;
	};
	
	onCaptchaChange = (e) => {
		this.captcha = e.target.value;
	};
	
	onSubmit = async () => {
		this.error = null;
		this.login = this.login.trim();
		this.password = this.password.trim();
		
		if (this.login.length === 0) {
			this.error = 'Введите логин';
			return;
		}
		if (this.password.length === 0) {
			this.error = 'Введите пароль';
			return;
		}
		
		try {
			await store.model.login(this.login, this.password, this.captcha);
		}
		catch (e) {
			this.tries = e.tries;
			this.error = t(e.code);
			this.captcha = '';
		}
	};
	
	onKeyUp = (e) => {
		if (e.keyCode === 13) {
			this.onSubmit();
		}
	};
	
	refresh = () => this.reloads++;
	
	render() {
		return <div className="login-layout">
			<div className="login-section">
				<div className="logo">
					<img src={logo} width={40} height={40} />
					<div className="title">Smart<strong>ERP</strong></div>
				</div>
				<div className="login-form">
					<div className="field">
						<label>Логин:</label>
						<input
							type="text"
							// autoCapitalize="off"
							// autoCorrect="off"
							// autoComplete="off"
							// spellCheck={false}
							placeholder={t('user.loginOrEmail')}
							name="email"
							value={this.login}
							onChange={this.onLoginChange}
						/>
					</div>
					<div className="field">
						<label>Пароль:</label>
						<input
							type="password"
							name="password"
							value={this.password}
							onKeyUp={this.onKeyUp}
							onChange={this.onPasswordChange}
							placeholder={t('user.password')}
						/>
					</div>
					{this.tries && this.tries.count >= 3 && <>
						<div className="field">
							<label>Код:</label>
							<input type="text" name="captcha" value={this.captcha} onChange={this.onCaptchaChange} />
						</div>
						<div className="captcha">
							<img src={`/api/captcha?${this.tries.count + this.reloads}`} ref={el => this.img = el} alt="" />
							<FontAwesomeIcon icon={faSyncAlt} className="reload" onClick={this.refresh} />
						</div>
					</>}
					<div className="submit">
						<Button className="btn-primary" onClick={this.onSubmit}>Войти</Button>
					</div>
					{this.error && <div className="error">{this.error}</div>}
					{/*{this.tries && <div>{this.tries.count} {this.tries.validTime ? formatTime(this.tries.validTime) : ''}</div>}*/}
				</div>
				<div className="login-footer">
					<div className="version">SmartPlatform {VERSION} ({BUILD})</div>
					<div className="copyright">© SmartUnit LLC {new Date().getFullYear()}</div>
				</div>
			</div>
		</div>;
	}
	
}
