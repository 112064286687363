import React from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { NumberValue, Loader, Button, Checkbox } from '@smartplatform/ui';
import store from 'client/store';
import './style.scss';
import t from 'i18n';
import { fioShort } from 'client/tools';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

const ORDER_INCLUDE = [
	{ relation: 'list', scope: { fields: ['id', 'color', 'name'] } },
	{ relation: 'client', scope: { fields: ['id', 'name'] } },
	{ relation: 'manager', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
	{
		relation: 'positions',
		scope: {
			where: { categoryId: { neq: null } },
			include: [
				{ relation: 'category', scope: { fields: ['id', 'name'] } }
			],
			order: 'id asc',
		}
	},
];
const HEADER_FIELDS = [
	t('order.n'),
	t('order.status'),
	t('order.client'),
	t('order.total'),
	t('order.paid'),
	t('order.manager'),
	t('order.category'),
	t('order.invoiceNumber'),
	t('order.contractNumber'),
	t('order.startDate'),
	t('order.endDate')
]
@inject('store') @observer
export default class OrdersReport extends React.Component {

	@observable query = {};
	@observable totalCount = 0;
	@observable records = null;
	@observable isLoading = false;
	@observable editMode = false;
	@observable selectedIds = {};
	@observable isToggleAllIds = false;
	@observable selectedColumns = {};

	headerFields = HEADER_FIELDS;

	tableStyle = {
		borderCollapse: 'collapse',
		border: '1px solid #ECF3F9',
		verticalAlign: 'middle',
	};
	headerStyle = {
		color: '#ECF3F9',
		background: '#0af'
	};
	oddRowStyle = {
		background: '#F8F8FF',
	};
	evenRowStyle = {
		background: '#fff'
	}

	constructor(props) {
		super(props);
		this.store = props.store;
		props.store.reload = this.reload;
		this.query = {
			where: this.store.compileFilter(this.store.availableListIds),
			include: ORDER_INCLUDE,
			order: 'id desc',
		};
		this.init();

	}
	@action init = async () => {
		this.isLoading = true;
		this.editMode = false;
		this.selectedIds = {};
		try {
			this.records = await store.model.ViewOrder.find(this.query);
			this.selectedRecords = this.records;
		} catch (e) {
			console.error('Fetching orders error:', e)
		}
		this.isLoading = false;
	}



	reload = () => {
		this.query = {
			...this.query,
			where: this.store.compileFilter(),
			skip: 0,
		};
		this.init()
	};

	refresh = () => {
		this.headerFields = HEADER_FIELDS;
		this.init();
	};

	renderTotal = (record) => record.total ? <NumberValue type="currency" value={record.total} /> : '';
	renderCategories = (positions) => {
		if (positions.length > 0) {
			const categoryNames = [...new Set(positions.map(pos => pos.category?.name))];
			return <div>{categoryNames.map((name, i) => <div key={i}>{name}</div>)}</div>
		}
	};

	downloadReport = ({ id, name }) => {
		const htmlTable = document.getElementById(id);
		if (htmlTable) {
			const html = htmlTable.outerHTML;
			const downloadLink = document.createElement('a');
			downloadLink.href = 'data:application/vnd.ms-excel, ' + '\uFEFF' + encodeURIComponent(html);
			downloadLink.download = `${name}.xls`;
			downloadLink.click();
		}
	};

	renderCell = (text, i, style) => {
		if ((typeof text === undefined) && (typeof text === null)) return;
		style = { ...style };
		if (!style.verticalAlign) style.verticalAlign = 'middle';
		return <td key={i} style={style}> {text} </td>
	};

	// функция редактирования строк
	onToggleRowCheckbox = (id) => {
		this.selectedIds[id] = !this.selectedIds[id];
		this.selectedIds = { ...this.selectedIds };
	};
	onEditModeChange = (boolean) => {
		this.editMode = boolean;
	};
	onEditStart = () => {
		this.isToggleAllIds = true;
		this.selectedColumns = {};
		this.prevSelectedIds = { ...this.selectedIds }
		this.onEditModeChange(true);
	};
	onEditFinish = () => {
		this.records = this.records.filter(rec => !this.selectedIds[rec.id]);
		this.headerFields = this.headerFields.map((rec, i) => !this.selectedColumns[i] ? rec : null);
		this.onEditModeChange(false);
	};
	onEditCancel = () => {
		this.selectedIds = { ...this.prevSelectedIds };
		this.onEditModeChange(false);
	};
	onToggleAllIds = (boolean) => {
		if (boolean) {
			this.selectedIds = {}
		}
		else this.records.forEach(r => this.selectedIds[r.id] = true);
		this.isToggleAllIds = boolean;
	};

	onToggleColumnCheckbox = (index) => {
		this.selectedColumns[index] = !this.selectedColumns[index];
		this.selectedColumns = { ...this.selectedColumns };
	};

	bodyFields = (record) => {
		const total = this.renderTotal(record);
		const categories = this.renderCategories(record.positions());
		return [
			this.editMode ? <div className='d-flex'><Checkbox onChange={() => this.onToggleRowCheckbox(record.id)} checked={!this.selectedIds[record.id]} /> {record.id} </div> : record.id,
			record.list?.name,
			record.client?.name,
			total,
			record.paid ? t('order.paid') : '',
			fioShort(record.manager),
			categories,
			record.invoiceNumber,
			record.contractNumber,
			record.startDate ? format(new Date(record.startDate), "dd.MM.yyyy") : '',
			record.endDate ? format(new Date(record.endDate), "dd.MM.yyyy") : '',
		];
	};

	render() {
		if (this.isLoading) {
			return <Loader size={18} />
		}
		if (this.records.length === 0) {
			return <div>{t('order.noOrders')}  <RefreshButton onClick={this.init} /></div>
		}

		let filename = format(new Date(), "dd.MM.yyyy");
		if (this.store.startDate0) {
			filename = `период ${format(new Date(this.store.startDate0), "dd.MM.yyyy")} - ${this.store.startDate1 ? format(new Date(this.store.startDate1), "dd.MM.yyyy") : format(new Date(), "dd.MM.yyyy")}`
		}

		const headerFields = this.headerFields.map((field, i) => {
			if (!field) return null;
			if (i === 0) return this.editMode ? <div className='d-flex'><Checkbox onChange={this.onToggleAllIds} checked={this.isToggleAllIds} /> {field}</div> : field;
			else return this.editMode ? <div className='column-chechbox'><Checkbox onChange={() => this.onToggleColumnCheckbox(i)} checked={!this.selectedColumns[i]} /> <div>{field}</div></div> : field;
		}).filter(r => !!r);

		return <div className="orders-report">
			<div className='toolbar'>
				{!this.editMode
					? <>
						<Button variant='primary' size='sm' onClick={() => this.downloadReport({ id: 'report-table', name: 'Отчет по заказам за ' + filename })}>{t('order.downloadReport')}</Button>
						<Button variant='primary' size='sm' onClick={this.onEditStart}>{t('order.editReport')}</Button>
						<RefreshButton onClick={this.refresh} />
					</>
					: <>
						<Button variant='primary' size='sm' onClick={this.onEditFinish}>{t('modify')}</Button>
						<Button variant='default' size='sm' onClick={this.onEditCancel}>{t('cancel')}</Button>
					</>
				}
			</div>
			<table style={this.tableStyle} id='report-table' className='report-table' >
				<thead>
					<tr>
						{headerFields.map((cell, i) => this.renderCell(cell, i, this.headerStyle))}
					</tr>
				</thead>
				<tbody>
					{this.records.map((record, rowIndex) => <tr key={rowIndex}>
						{this.bodyFields(record).filter((r, i) => !!this.headerFields[i]).map((cell, i) => {
							let style = rowIndex % 2 === 0 ? this.evenRowStyle : this.oddRowStyle
							style = { ...style, textAlign: 'left', };
							return this.renderCell(cell, i, style)
						})}
					</tr>
					)}
				</tbody>
			</table>
		</div>;
	}
}

const RefreshButton = ({ onClick }) => <Button variant='primary' size='sm' onClick={onClick}>
	<FontAwesomeIcon icon={faSyncAlt} />
</Button>