import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { NumberValue, Avatar } from '@smartplatform/ui';
import { Labels } from 'components';
import { relative } from 'client/tools';
import t from 'i18n';

@observer
export default class OrderCard extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		const { order, list, canViewSum } = this.props;

		const positions = typeof order.positions === 'function' ? order.positions() : [];
		const _positions = positions.then ? [] : positions;
		// console.log('positions', _positions.length);

		return <>
			<div className="order-card-top">
				{list ?
					<div className={'id' + (list.closing ? ' closed' : '')}># {order.id} {JSON.stringify(list.closing)} {list.closing && <FontAwesomeIcon icon={faCheck} />}</div>
					:
					<div className="id"># {order.id}</div>
				}
				<div className="last-udpate">{relative(order.updatedAt)}</div>
			</div>
			<div className="order-card-main">
				{order.manager && <Avatar className="manager" user={order.manager} />}
				{order.description && <div className="name">{order.description}</div>}
				<div className="client">
					{t('client.title')}: <em>{order.clientName || '-'}</em>
				</div>
				{_positions.length > 0 && <div className="positions">
					{_positions.slice(0, 2).map((position, i) => <div key={position.id} className="position">
						<div className="category">{i + 1}. {position.category.name}</div>
						<div className="amount">{position.amount} шт.</div>
						{canViewSum && <div className="price"><NumberValue value={position.price} type="currency" /></div>}
					</div>)}
					{_positions.length > 2 && <div>...</div>}
				</div>}
				{_positions.length > 0 && <div className="total">
					{canViewSum && <div className="price">{t('order.totalShort')}: <em><NumberValue value={order.total} type="currency" /></em></div>}
					<div className="positions-count">{t('order.positionsCount')}: {_positions.length}</div>
				</div>}
			</div>
			{/*
			{(order.labels().length > 0) && <div className="order-card-bottom evented">
				<div className="labels">
					{order.labels().length > 0 && <Labels record={order} labels={order.labels()} size={8} />}
				</div>
			</div>}
*/}
		</>;
	}

}
