import AddIcon from '!!svg-react-loader!client/img/add-btn.svg';
import { Column, Table } from '@smartplatform/ui';
import { DeleteButton } from 'components';
import t from 'i18n';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import EditProcess from './EditProcess';
import store from 'client/store'
@inject('store') @observer
export default class Processes extends React.Component {

	@observable processes = [];
	@observable selectedProcess = null;

	constructor(props) {
		super(props);
		this.order = this.props.store.order;
		this.init()
	}

	init = async () => {
		this.processes = await this.order.processes.find({
			include: [
				'operation', 'equipment', 'workshop', 'targetWorkshop',
				{ relation: 'position', scope: { include: ['category'] } }]
		});
	};

	addProcess = async () => {
		const newProcessLog = new store.model.ProcessLog();
		newProcessLog.order = this.order;
		await newProcessLog.save();
		const orderLog = new store.model.OrderLog();
		orderLog.orderId = this.order.id;
		orderLog.processLogId = newProcessLog.id;
		orderLog.updated = ['newProcess'];
		await orderLog.save();
		this.selectedProcess = newProcessLog;
		await this.order.save();
	};

	deleteProcess = async (processLog) => {
		await processLog.delete();
		const orderLog = new store.model.OrderLog();
		orderLog.orderId = this.order.id;
		orderLog.updated = ['deletedProcess'];
		await orderLog.save();
		await this.order.save();
		await this.init();
	};

	closeEditPopup = async () => {
		this.selectedProcess = null;
		await this.init()
	}


	onProcessClick = process => this.selectedProcess = process;

	renderActions = rowData => {
		const { record } = rowData;
		return <DeleteButton onConfirm={() => this.deleteProcess(record)} />;
	};

	renderCategory = (position) => position.category && position.category.name;

	render() {
		return <div className="processes-section" >
			<div className="form-field">
				<label>{t('process.plural')}: </label>
			</div>
			{(this.processes.length > 0) && <div className="processes-list" >
				<Table multiline rows={this.processes} onRowClick={this.onProcessClick} >
					<Column relation='position' computed={this.renderCategory} label={t('process.position')} />
					<Column relation='workshop' property="name" label={t('process.workshop.title')} />
					<Column relation='equipment' property="name" label={t('process.equipment')} />
					<Column relation='operation' property="name" label={t('process.operation.title')} />
					<Column property="adjustmentsNumber" label={t('process.adjustmentsNumber')} />
					<Column property="operatorsNumber" label={t('process.operatorsNumber')} />
					<Column property="workingTime" label={t('process.workingTime')} />
					<Column relation='targetWorkshop' property="name" label={t('process.targetWorkshop')} />
					<Column width={40} >{this.renderActions}</Column>
				</Table>
			</div>
			}
			<div className="add-row"><AddIcon onClick={this.addProcess} className="add-btn">add</AddIcon>
			</div>
			{this.selectedProcess && <EditProcess onClose={this.closeEditPopup} record={this.selectedProcess} processes={this.processes} />}
		</div>;
	}

}
