import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { SmallDeleteButton } from 'components';

import { getIconForExtension } from './icons';
import { formatDate } from 'client/tools';
import t from 'i18n';

@observer
export default class Attachment extends React.Component {

	static propTypes = {
		attachment: PropTypes.object,
		property: PropTypes.string,
		onDelete: PropTypes.func,
	};
	
	static defaultProps = {
		property: 'filename',
	};
	
	constructor(props) {
		super(props);
	}
	
	delete = async () => {
		// throw new Error('Thrown error!')
		const { attachment, property } = this.props;
		console.log('delete', attachment, property);
		this.props.onDelete && this.props.onDelete(attachment);
		try {
			await attachment.deleteFile(property);
		}
		catch (e) {}
		await attachment.delete();
	};
	
	render() {
		const { attachment, property, isNew } = this.props;
		
		const ext = attachment[property].split('.').slice(-1);
		const icon = getIconForExtension(ext);
		
		const msg = <>{t('deleteFile')}<br /><em>{attachment[property]}</em>?</>;
		
		return <div className="attachment">
			<span className="icon">{icon}</span>
			<div className="info">
				<a className="file-name" target="_blank" href={attachment.downloadFile(property)}>{attachment[property]}</a>
				{!isNew && <SmallDeleteButton onConfirm={this.delete} confirmMessage={msg} portalClassName="confirm-delete" />}
				<div className="date">
					{formatDate(attachment.createdAt, 'dd.MM.yyyy HH:mm')}
				</div>
			</div>
		</div>;
	}
	
}
