import React from 'react';
import { observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { Table, Column, NumberValue, Pager } from '@smartplatform/ui';
import { DeleteButton, AddButton } from 'components';
import EditPricePosition from './EditPricePosition';
import store from 'client/store';
import t from 'i18n';

@withRouter @observer
export default class PricePositions extends React.Component {

	@observable priceCategory = null;
	@observable selectedPosition = null;
	@observable priceCategoryFeatures = [];
	@observable error = null;
	@observable updates = 0;
	@observable isInitialized = false;

	@observable query = {};
	@observable pageSize = 10;
	@observable page = 1;
	@observable totalCount = 0;

	constructor(props) {
		super(props);
		this.id = parseInt(this.props.match.params.id);
		this.query = {
			where: { categoryId: this.id },
			include: [
				{
					relation: 'values',
					scope: {
						include: [
							{ relation: 'feature', scope: { fields: ['id', 'name'] }},
							{ relation: 'featureValue', scope: { fields: ['id', 'name', 'featureId'] }},
						],
					}
				}
			],
			skip: 0,
			limit: this.pageSize,
			order: 'id desc'
		};
		this.init();
	}

	init = async () => {
		this.priceCategory = await store.model.PriceCategory.findById(this.id, {
			include: [
				{
					relation: 'priceCategoryFeatures',
					scope: {
						where: { featureId: { neq: null }},
						include: [
							{ relation: 'feature', scope: { fields: ['id', 'name', 'type', 'values'] }},
						],
						order: 'weight asc',
					}
				},
			],
		});
		this.priceCategoryFeatures = this.priceCategory.priceCategoryFeatures();
		this.isInitialized = true;
	};

	getInstance = instance => this.table = instance;

	reload = () => {
		console.log('reload', this.table);
		if (this.page !== 1) {
			this.onPageChange(1);
		}
		else if (this.table) {
			this.table.reload();
		}
	};

	onQueryUpdate = query => {
		// console.log('onQueryUpdate', query);
		const { _totalCount, ...rest } = query;
		this.query = rest;
		this.totalCount = _totalCount;
	};

	onPageChange = page => {
		this.page = page;
		this.query = {
			...this.query,
			skip: (this.page - 1) * this.pageSize,
		};
	};

	addPosition = async () => {
		const position = new store.model.PricePosition();
		position.category = this.priceCategory;
		// TODO: try-catch или remote-метод
		await position.save();
		const positionValuesPromises = this.priceCategoryFeatures.map(priceCategoryFeature => new Promise((resolve, reject) => {
			const positionValue = new store.model.PricePositionValue();
			positionValue.position = position;
			positionValue.feature = priceCategoryFeature.feature;
			positionValue.save().then(resolve);
		}));
		await Promise.all(positionValuesPromises);
		this.selectedPosition = position;
		this.reload();
	};

	deletePosition = async (i, position) => {
		// this.positions.splice(i, 1);
		await position.delete();
		this.reload();
	};

	renderActions = ({ record, row }) => {
		return <DeleteButton onConfirm={() => this.deletePosition(row, record)} />
	};

	renderPositionValue = (params, priceCategoryFeature) => {
		const { relation: values } = params;

		const feature = priceCategoryFeature.feature;
		// console.log('> values', values);

		const empty = priceCategoryFeature.required ? <span className="red required">*</span> : '-'

		const positionValue = values.find(positionValue => positionValue.featureId === feature.id);
		if (!positionValue) return empty;//feature.type === 'boolean' ? t('no') : empty;

		let value = positionValue.stringValue || empty;
		if (feature.type === 'number') {
			value = positionValue.numberValue || empty;
		}
		else if (feature.type === 'boolean') {
			value = positionValue.booleanValue === true ? t('yes') : empty;//t('no');
		}
		else if (feature.type === 'list') {
			value = positionValue.featureValue ? <>{positionValue.featureValue.name}</> : empty;
		}
		return value;
	};

	showEditPopup = position => this.selectedPosition = position;

	closeEditPopup = async (isUpdated) => {
		if (isUpdated) {
			this.reload();
		}
		this.selectedPosition = null;
	};

	render() {
		if (!this.id) return null;
		if (!this.isInitialized) return '...';
		if (this.error) return <div className="error">{this.error}</div>;

		if (!this.priceCategory) return null;

		const featuresColumns = this.priceCategoryFeatures.map(priceCategoryFeature => (
			<Column key={priceCategoryFeature.id} relation="values" label={priceCategoryFeature.feature.name}>
				{params => this.renderPositionValue(params, priceCategoryFeature)}
			</Column>
		));

		return <div className="price-positions">
			<Pager itemsPerPage={this.pageSize} current={this.page} onChange={this.onPageChange} totalCount={this.totalCount} />
			<Table model={store.model.PricePosition} query={this.query} onQueryUpdate={this.onQueryUpdate} onRowClick={this.showEditPopup} getInstance={this.getInstance}>
				<Column width={50} label={t('number')}>{params => params.row + 1}</Column>
				<Column width={400} property="name" label={t('pricePosition.name')}/>
				{featuresColumns}
				<Column width={150} property="amountFrom" label={t('pricePosition.from')}/>
				<Column width={150} property="amountTo" label={t('pricePosition.to')}/>
				<Column width={150} className="text-right" property="price" label={t('pricePosition.priceForOne')}><NumberValue type="currency" /></Column>
				<Column clickable={false} className="text-right">{this.renderActions}</Column>
			</Table>
			<AddButton onClick={this.addPosition}/>
			{this.selectedPosition &&
				<EditPricePosition
					pricePosition={this.selectedPosition}
					onClose={this.closeEditPopup}
				/>
			}
		</div>;
	}
};
