import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Popup, Form, Input, Field, Row, NumberInput, Select, Checkbox, Trigger, Loader } from '@smartplatform/ui';
import { DeleteButton, AddButton } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export default class EditWaybill extends React.Component {

	@observable isLoading = true;
	@observable error = null;
    @observable record = null;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
        this.order = this.props.order;
        this.record = this.props.record ||  new store.model.Waybill({ orderId: this.order.id });
		this.isLoading = false;
	};
    cancel = () => {
        this.props.onClose && this.props.onClose();
    }
	render() {
		return(
            <Popup onClose={this.cancel} width={700}>
                {
                    this.isLoading ?
                    <Loader /> :
                    <Form record={this.record} stay onSave={this.props.onSave} onDelete={this.props.onDelete}>
                        <Row>
                            <Field relation="nomenclature" property="name" label={t('order.waybill.name')} />
                            <Field property="gramms" label={t('order.waybill.gramms')}>
                                <NumberInput positiveOnly />
                            </Field>
                        </Row>
                        <Row>
                            <Field property="count" label={t('order.waybill.count')}>
                                <NumberInput positiveOnly integerOnly/>
                            </Field>
                            <Field property="density" label={t('order.waybill.density')}>
                                <NumberInput positiveOnly />
                            </Field>
                        </Row>
                        <Row>
                            <Field property="gaveOut">
                                <Checkbox label={t('order.waybill.gaveOut')} />
                            </Field>
                            <Field property="defect">
                                <Checkbox label={t('order.waybill.defect')} />
                            </Field>
                            <Field property="return">
                                <Checkbox label={t('order.waybill.return')} />
                            </Field>
                        </Row>
                    </Form>
                }
            </Popup>
        );
	}

}
