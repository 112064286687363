import React from "react";

export const NomenclatureGroups = ({
    groups = [],
    onFilter = () => {},
    active = []
}) => {
    const handleListClick = e => e.stopPropagation();
    if(!groups.length) return <></>
    return(
        <ul className={`nomenclature-groups__list`} onClick={handleListClick}>
            {groups.map(group => (
                <li
                    onClick={e => onFilter(group)}
                    className={active.includes(group.id) ? 'active' : ''}
                >
                    {group.name}
                    <NomenclatureGroups
                        groups={group.children()}
                        onFilter={onFilter}
                        active={active}
                    />
                </li>
            ))}

        </ul>
    )
}