import React from 'react';
import PropTypes from 'prop-types';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { generatePath } from 'react-router';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Pager, Loader } from '@smartplatform/ui';
import { WrappedTable } from 'components';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

@withRouter @observer
export default class List extends React.Component {

	static propTypes = {
		model: PropTypes.any,
		title: PropTypes.any,
		filter: PropTypes.object,
		path: PropTypes.string,
		order: PropTypes.string,
		pageSize: PropTypes.number,
		properties: PropTypes.object, // предустановленные данные для новой записи
		disableFilters: PropTypes.bool,
		disableCreate: PropTypes.bool,
	};

	static defaultProps = {
		pageSize: 20,
		order: 'id desc',
	};

	@observable query = {};
	@observable records = [];
	@observable isLoading = false;
	@observable page = 1;
	@observable search = '';
	initial = true;

	constructor(props) {
		super(props);
		if (props.title) store.ui.title = props.title;
		this.page = this.getPage();
		this.doSearch = debounce(this.doSearch, 500, { leading: false, trailing: true });
		this.query = {
			...this.props.filter,
			skip: (this.page - 1) * this.props.pageSize,
			limit: this.props.pageSize,
			order: props.order
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const page = this.getPage();
		if(prevProps.filter !== this.props.filter)
			this.query = {
				...this.query,
				...this.props.filter
			}
		if (page !== this.page) {
			this.page = page;
			this.query = {
				...this.query,
				skip: (page - 1) * this.props.pageSize,
				limit: this.props.pageSize
			};
		}
	}

	getPage = () => this.props.match && this.props.match.params ? parseInt(this.props.match.params.page) || 1 : 1;

	onPageChange = newPage => {
		const { page, ...params } = this.props.match.params;
		let pattern = this.props.match.path;
		if (!page && newPage !== 1) pattern += (this.props.path || '') + '/page/:page';
		// if (newPage === 1) pattern = pattern.replace(/\/page\/:page/, '');
		let url = generatePath(pattern, {
			...params,
			page: newPage, //newPage > 1 ? newPage : undefined,
		});
		store.route.push({ path: url });
	};

	create = () => {
		const { page, ...params } = this.props.match.params;
		let pattern = this.props.match.path;
		pattern = pattern.replace(/\/page\/:page/, '');
		pattern += (this.props.path || '') + '/create';
		let path = generatePath(pattern, {...params});
		const route = {
			path,
			// params: this.props.properties,
		};
		store.route.push(route);
	}

	onRowClick = record => {
		store.route.push({ path: this.makePath(record) });
	};

	makePath = record => {
		const { page, ...params } = this.props.match.params;
		let pattern = this.props.match.path;
		pattern = pattern.replace(/\/page\/:page/, '');
		pattern += (this.props.path || '') + '/:id';
		let path = generatePath(pattern, {
			...params,
			id: record.id,
		});
		return path;
	};

	onQueryUpdate = (query, prevQuery) => {
		this.initial = false;
		console.log('onQueryUpdate', query);
		this.query = query;
		if (prevQuery && this.page > 0 && query.skip === 0) {
			const { page, ...params } = this.props.match.params;
			let pattern = this.props.match.path;
			pattern = pattern.replace(/\/page\/:page/, '');
			let path = generatePath(pattern, { ...params });
			store.route.push({ path });
		}
	};

	onLoadStart = () => this.isLoading = true;
	onLoadEnd = () => this.isLoading = false;

	onSearch = e => {
		this.search = e.target.value;
		this.doSearch();
	};

	doSearch = () => {
		const trimmed = this.search.trim();
		const { _totalCount, ...query } = this.query;
		this.page = 1;
		this.query = {
			...query,
			search: trimmed.length > 0 ? trimmed : undefined,
			skip: 0,
		};
	};

	render() {
		const { children, model, path, pageSize, match } = this.props;

		const className = classNames('table-with-pager', {
			loading: this.isLoading,
			initial: this.isLoading && this.initial,
		});

		// console.log('list render', className);

		return <div className="fixed-page model-list">
			{
				!this.props.disableFilters ?
				<div className="filters">
					<input type="text" value={this.search} onChange={this.onSearch} placeholder={t('search')} />
					{!this.props.disableCreate ? <Button onClick={this.create} variant="primary">Создать</Button> : ''}
				</div> :
				''
			}
			{(this.isLoading && this.initial) && <Loader />}
			<div className={className}>
				<Pager current={this.page} totalCount={this.query._totalCount || 0} onChange={this.onPageChange} itemsPerPage={pageSize} />
				<WrappedTable model={model} query={this.query} onQueryUpdate={this.onQueryUpdate} onRowClick={this.onRowClick} onLoadStart={this.onLoadStart} onLoadEnd={this.onLoadEnd}>
					{children}
				</WrappedTable>
			</div>
		</div>;
	}

}
