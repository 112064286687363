import t from 'i18n';
import {
    faFolder,
    faCheckCircle,
    faPortrait,
    faBriefcase,
    faCalculator,
    faUserFriends,
    faReceipt,
    faTags,
    faUserTag,
    faCheck,
    faCheckDouble,
    faUser,
    faUserCog,
    faLock,
    faUserLock,
    faCog,
    faShoppingCart,
    faListUl,
    faCalendarCheck,
    faCogs,
    faRandom,
    faWarehouse,
    faSitemap,
    faBook,
    faFileExcel,
    faCubes,
    faStream,
} from '@fortawesome/free-solid-svg-icons';

export default [
    {
        type: 'submenu',
        id: 'management',
        label: t('management'),
        items: [
            {
                type: 'item',
                label: t('project.plural'),
                path: '/projects',
                icon: faFolder,
                enabled: (store) => store.model.Project && store.model.Project.INFO.READ,
            },
            {
                type: 'item',
                label: t('task.plural'),
                path: '/tasks',
                icon: faCheckCircle,
                enabled: (store) => store.model.Task && store.model.Task.INFO.READ,
            },
            {
                type: 'item',
                path: '/persons',
                label: t('person.plural'),
                icon: faPortrait,
                enabled: (store) => store.model.Person && store.model.Person.INFO.READ,
            },
            {
                type: 'item',
                path: '/clients',
                label: t('client.plural'),
                icon: faBriefcase,
                enabled: (store) => store.model.Client && store.model.Client.INFO.READ,
            },
        ],
    },
    {
        type: 'submenu',
        id: 'finance',
        label: t('finance'),
        // enabled: store => store.model.Budget && store.model.Budget.INFO.READ,
        enabled: (store) => false,
        items: [
            {
                type: 'item',
                label: t('budget.plural'),
                path: '/budgets',
                icon: faCalculator,
            },
            {
                type: 'item',
                label: t('budgetRole.plural'),
                path: '/budgetroles',
                icon: faUserFriends,
            },
            {
                type: 'item',
                path: '/payments',
                label: t('payment.plural'),
                icon: faReceipt,
            },
        ],
    },
    {
        type: 'submenu',
        id: 'sales',
        label: t('sales.title'),
        items: [
            {
                type: 'item',
                label: t('order.plural'),
                path: '/orders',
                icon: faShoppingCart,
                enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
            },
            {
                type: 'item',
                label: t('order.report'),
                path: '/ordersreport',
                icon: faFileExcel,
                enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
            },
            {
                type: 'item',
                label: t('priceList.title'),
                path: '/pricecategories',
                icon: faListUl,
                enabled: (store) => store.model.PriceCategory && store.model.PriceCategory.INFO.READ,
            },
            {
                type: 'item',
                label: t('feature.plural'),
                path: '/features',
                icon: faCog,
                enabled: (store) => store.model.Feature && store.model.Feature.INFO.READ,
            },
            {
                type: 'item',
                label: t('orderList.plural'),
                path: '/orderlists',
                icon: faCalendarCheck,
                enabled: (store) => store.model.OrderList && store.model.OrderList.INFO.READ,
            },
            {
                type: 'item',
                label: t('orderPositionRole.plural'),
                path: '/orderpositionroles',
                icon: faUserCog,
                enabled: (store) => store.model.OrderPositionRole && store.model.OrderPositionRole.INFO.READ,
            },
            {
                type: 'item',
                label: t('orderPositionParameters.plural'),
                path: '/orderpositionparameters',
                icon: faCogs,
                enabled: (store) => store.model.OrderPositionParameter && store.model.OrderPositionParameter.INFO.READ,
            },
            {
                type: 'item',
                label: t('technologyRoadmap.title'),
                path: '/technicalroadmap',
                icon: faRandom,
                enabled: (store) => store.model.Order && store.model.Order.INFO.READ,
            },
            {
                type: 'item',
                label: t('warehouse.nomenclature'),
                path: '/nomenclature',
                icon: faStream,
                enabled: (store) => store.model.Nomenclature && store.model.Nomenclature.INFO.READ,
            },
        ],
    },
    {
        type: 'submenu',
        id: 'settings',
        label: t('settings'),
        enabled: (store) => store.model.ACL && store.model.ACL.INFO.WRITE,
        items: [
            {
                type: 'item',
                label: t('label.plural'),
                path: '/labels',
                icon: faTags,
            },
            {
                type: 'item',
                label: t('projectRole.plural'),
                path: '/projectroles',
                icon: faUserTag,
            },
            {
                type: 'item',
                path: '/boardlists',
                label: t('boardList.plural'),
                icon: faCheck,
            },
            {
                type: 'item',
                path: '/projectlists',
                label: t('projectList.plural'),
                icon: faCheckDouble,
            },
        ],
    },
    {
        type: 'submenu',
        id: 'warehouse',
        label: t('enterprisedata.sidebar.submenu'),
        items: [
            {
                type: 'item',
                label: t('enterprisedata.sidebar.settings'),
                path: '/1c-options',
                icon: faCog,
                enabled: (store) => store.model.EdOption && store.model.EdOption.INFO.READ,
            },
            {
                type: 'item',
                label: t('enterprisedata.sidebar.nodes'),
                path: '/1c-nodes',
                icon: faSitemap,
                enabled: (store) => store.model.EdNode && store.model.EdNode.INFO.READ,
            },
            {
                type: 'item',
                label: t('enterprisedata.sidebar.objects'),
                path: '/1c-objects',
                icon: faCubes,
                enabled: (store) => store.model.EdObject && store.model.EdObject.INFO.READ,
            },
            {
                type: 'item',
                label: t('enterprisedata.sidebar.journal'),
                path: '/1c-journal',
                icon: faBook,
                enabled: (store) => store.model.EdFile && store.model.EdFile.INFO.READ,
            },
        ],
    },
    {
        type: 'submenu',
        id: 'admin',
        label: t('admin'),
        enabled: (store) => store.model.ACL && store.model.ACL.INFO.WRITE,
        items: [
            {
                type: 'item',
                label: t('users'),
                path: '/users',
                icon: faUser,
            },
            {
                type: 'item',
                label: t('roles'),
                path: '/roles',
                icon: faUserCog,
            },
            {
                type: 'item',
                path: '/acls',
                label: t('acls'),
                icon: faLock,
            },
            {
                type: 'item',
                path: '/rolemappings',
                label: t('roleMappings'),
                icon: faUserLock,
            },
        ],
    },
];
