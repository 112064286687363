import React from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { TextEncoder } from 'text-encoding';
import { saveAs } from 'file-saver';

import { Kanban } from '@smartplatform/ui';
import TaskCard from './task-card';
import store from 'client/store';
import { formatDate, fio } from 'client/tools';
import pStore, { INITIAL_LIMIT } from './store';

export const DEFAULT_PRIORITY = 1000;

@observer
export default class Tasks extends React.Component {
	
	renderTask = (task, list, options) => <TaskCard task={task} list={list} {...options} />;
	
	@action onChange = async ({ item, text, prev, next, list, index }) => {
		console.log('onChange', item, prev, next, list, index);
		pStore.saving = true;
		
		const task = new store.model.Task(item);
		task.boardListId = list.id;
		task.closed = list.closing || false;
		if (list.closing) task.closedAt = new Date();

		// await this.fakeDelay(2000);
		
		// throw new Error(`Ошибка переноса задачи #${task.id} в список "${list.name}".`);
		
		if (!item) {
			task.name = text;
			task.projectId = pStore.project.id;
		}
		
		if (!prev) {
			task.priority = next ? next.priority * 2 : DEFAULT_PRIORITY;
		}
		else if (!next) {
			task.priority = prev ? prev.priority / 2 : DEFAULT_PRIORITY;
		}
		else {
			task.priority = (prev.priority + next.priority) / 2;
		}
		
		try {
			await task.save();
			pStore.saving = false;
		}
		catch (e) {
			pStore.saving = false;
			throw e;
		}
		
		return {
			id: task.id,
			listId: task.boardListId,
			path: `/tasks/${task.id}`,
			data: task,
		};
	};
	
	gotoItem = path => store.route.push({ path });
	
	fakeDelay = (delay = 1000) => new Promise(resolve => setTimeout(resolve, delay));
	
	getInstance = instance => pStore.kanban = instance;

	exportList = async (list) => {
		const taskWhere = pStore.compileTaskFilter();
		const tasks = await store.model.ViewTask.find({
			where: {
				and: [
					...taskWhere.and,
					{ boardListId: list.id },
				],
			},
			include: ['user'],
			order: 'priority desc',
		});
		console.log('exportList', list, tasks);
		if (tasks.length > 0) {
			let csvContent = '#;"Номер";"Название";"Отвественный";"Обновлено"\r\n';
			tasks.forEach((task, i) => {
				const data = [
					i + 1,
					task.id,
					'"' + (task.name ? task.name.replace(/"/mg, '""') : '') + '"',
					task.user ? '"' + fio(task.user) + '"' : '',
					formatDate(task.updatedAt),
				];
				const row = data.join(';');
				csvContent += row + '\r\n';
			});
			let csv = new TextEncoder('windows-1251', { NONSTANDARD_allowLegacyEncoding: true }).encode([csvContent]);
			let blob = new Blob([csv], { type: 'text/csv;charset=windows-1251' });
			saveAs(blob, `${pStore.project.name} - ${list.name}.csv`);
		}

	};
	
	render() {
		if (pStore.isLoading && !pStore.project) return null;
		if (!pStore.project) return null;

		const project = {
			name: pStore.project.name,
			lists: pStore.project.lists().map(list => {
				return {
					id: list.id,
					name: list.name,
					color: list.color,
					totalCount: list.totalCount,
					data: list,
					items: list.tasks().map(task => ({
						id: task.id,
						listId: task.boardListId,
						path: `/tasks/${task.id}`,
						data: task,
					})),
				};
			})
		};
		
		return <div className="tasks-kanban">
			{pStore.project.lists().length > 0 ?
				<Kanban
					key={pStore.project.id}
					project={project}
					renderItem={this.renderTask}
					itemClassName="task-card"
					onChange={this.onChange}
					instance={this.getInstance}
					loadMore={pStore.loadMore}
					onError={pStore.onError}
					gotoItem={this.gotoItem}
					itemsLimit={INITIAL_LIMIT}
					exportList={this.exportList}
				/>
				:
				<>
					Не заданы <Link to={`/projects/${pStore.project.id}/lists`}>состояния задач</Link>
				</>
			}
		</div>;
	}
	
}
