import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, Loader } from '@smartplatform/ui';
import store from 'client/store';

@observer
export default class Pdf extends React.Component {

	@observable html = null;
	@observable isLoading = false;

	constructor(props) {
		super(props);
	}

	onPDFContentMount = el => {
		this.html = el ? el.innerHTML : null;
	};

	openPDF = async () => {
		this.isLoading = true;
		const buffer = await store.model.Order.htmlToPDF({ html: this.html });
		this.isLoading = false;

		const pdfString = "data:application/pdf;base64,"+ buffer.$data.toString();

		const iframe = `<iframe width="100%" height="100%" src="${pdfString}"'></iframe>`;
		const newWindow = window.open();
		newWindow.document.open();
		newWindow.document.write(iframe);
		newWindow.document.close();
	};

	render() {
		return <div>
			<Button onClick={this.openPDF}>Open PDF</Button>
			{this.isLoading && <Loader size={18} />}
			<br /><br/>

			<div ref={this.onPDFContentMount}>
				<div>some react component</div>
			</div>
		</div>;
	}

}
