import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Phone } from 'components';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import store from 'client/store';
import './style.scss';

@observer
export default class Layout extends React.Component {

	onPageMount = el => {
		if (el) {
			el.addEventListener('scroll', this.onScroll);
		}
	};

	onScroll = e => {
		// console.log('onScroll', e.target.scrollTop);
		window.dispatchEvent(new CustomEvent('myscroll', { detail: { scrollTop: e.target.scrollTop }}));
	};

	render() {
		return <div className="layout">
			<Header />
			<div className="content">
				<Sidebar />
				<div className="page" ref={this.onPageMount}>
					{store.ui.title && <Phone><div className="page-title">{store.ui.title || ''}</div></Phone>}
					{this.props.children}
				</div>
			</div>
		</div>;
	}
	
}
