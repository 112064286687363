import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

import { Field, Row } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import Contacts from './Contacts';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Edit extends React.Component {

	@observable record = null;

	getRecord = record => this.record = record;

	render () {
		const { modelName, path, match } = this.props;
		const id = parseInt(match.params.id);

		return <div className="fixed-page">
			<ModelEdit model={store.model[modelName]} stay path={path} id={id} getRecord={this.getRecord} title={t('client.title')}>
				<Row>
					<Field property="name" label={t('client.title')}/>
					{/*<Field property="personName" label={t('client.personName')}/>*/}
					{/*<Field property="personRole" label={t('client.personRole')}/>*/}
				</Row>
{/*
				<Row>
					<Field property="phone" label={t('phone')}/>
					<Field property="email" label={t('email.title')}/>
					<div/>
				</Row>
*/}
				<Field property="comment" label={t('comment.title')}><textarea rows={5}/></Field>
			</ModelEdit>
			{this.record && this.record.id && <Contacts client={this.record} />}
		</div>;
	}
};
