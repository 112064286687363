import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';

import { Popover, Loader } from '@smartplatform/ui';
import MatchingPositions from './MatchingPositions';
import store from 'client/store';
import t from 'i18n';

@observer
export default class MatchesCount extends React.Component {

	@observable isLoading = true;
	@observable count = 0;

	constructor(props) {
		super(props);
		this.update = debounce(this.update, 500, { leading: false, trailing: true });
		props.getInstance && props.getInstance({ update: this.update });
		// this.load();
	}

	update = () => this.load();

	load = async () => {
		const { orderPosition, category, orderPositionValues } = this.props;
		if (category) {
			this.isLoading = true;
			this.count = await store.model.PriceCategory.findMatchesCount({
				categoryId: category.id,
				amount: orderPosition.amount,
				orderPositionValues,
			});
		}
		this.isLoading = false;
	};

	getPopoverInstance = instance => this.popover = instance;

	onSelect = pricePosition => {
		if (this.popover) this.popover.close();
		this.props.onSelect(pricePosition);
	};

	render() {
		const { category, orderPosition, orderPositionValues } = this.props;

		if (!category) {
			return <div className="matches-count disabled">{t('orderPosition.noCategorySelected')}</div>;
		}

		if (this.isLoading) {
			return <div className="matches-count disabled">{t('orderPosition.lookingForMatches')} <Loader size={18} color="#aaa" /></div>;
		}

		if (this.count === 0) {
			return <div className="matches-count disabled">{t('orderPosition.noMatches')}</div>;
		}

		const popup = <MatchingPositions
			orderPosition={orderPosition}
			category={category}
			orderPositionValues={orderPositionValues}
			onSelect={this.onSelect}
			total={this.count}
		/>;

		return <Popover trigger="click" className="matches-count active" getInstance={this.getPopoverInstance} content={popup}>
			{t('orderPosition.matchingPricePositions')}: {this.count}
		</Popover>;
	}

}
