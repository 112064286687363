import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Popup, Form, Field } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

@observer
export default class NewClient extends React.Component {


	constructor(props) {
		super(props);
		this.client = new store.model.Client();
	}
	onSave = async (client) => {
		if (client.name) {
			this.props.order.client = client;
			await this.props.order.save();
			this.props.onClose();
		};
	};

	render() {
		return <Popup width={600} onClose={this.props.onClose} header={t('client.title')}>
			<Form record={this.client} stay onSave={this.onSave} >
				<Field property="name" label={t('client.title')} />
				<Field property="comment" label={t('comment.title')} ><textarea rows={5} /></Field>
			</Form>
		</Popup>;
	}

}
