import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import Mode from './Mode';
import Toolbar from './toolbar/Toolbar';
import './style.scss';

@observer
export default class Overview extends React.Component {

	render() {
		return <div className="orders-overview">
			<Toolbar mode={this.props.mode}/>
			<Mode mode={this.props.mode}/>
		</div>;
	}

}
