import React from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';

import { Button, Row, Form, Field, RelationSelect, ManyToManySelect, ColorPicker, Loader } from '@smartplatform/ui';
import { getRoleNames, relative } from 'client/tools';
import { Editor, Comments, LabelsEdit, User } from 'components';
import { DEFAULT_PRIORITY } from './projects-kanban/ProjectsKanban';
import ProjectDelete from './ProjectDelete';
import store from 'client/store';
import t from 'i18n';

@observer
export default class Edit extends React.Component {
	
	@observable record = null;
	@observable error = null;
	@observable isLoading = true;
	@observable isSaving = false;
	@observable editDescription = false;
	@observable roles = [];

	constructor (props) {
		super(props);
		this.init();
	}
	
	@action init = async () => {
		this.error = null;
	
		this.id = parseInt(this.props.match.params.projectId);
		if (this.id) {
			try {
				this.record = await store.model.Project.findById(this.id, {
					include: ['owner']
				});
			}
			catch (e) {
				this.error = e.message;
			}
		}
		else {
			this.record = new store.model.Project();
			this.record.projectListId = 1;
			this.record.priority = DEFAULT_PRIORITY;
		}
		this.roles = await getRoleNames();
		this.isLoading = false;
	};
	
	onEditorInit = editor => this.editor = editor;

	onDescriptionChange = content => {
		this.record.description = content;
		this.changed = true;
	};

	switchDescription = async () => {
		if (this.editDescription && this.changed) {
			const images = await this.uploadImages();

			this.record.description = this.editor.getContent();
			await this.record.save();

			for (let imgData of images) {
				console.log('>', imgData);
				const { uploadUri } = imgData;
				const filename = uploadUri.split('/').slice(-1)[0];
				const id = filename ? parseInt(filename.replace(/-filename/, '')) : null;
				if (id) await this.record.attachments.add(id);
			}
		}
		this.editDescription = !this.editDescription;
	};

	beforeSave = record => {
		console.log('> id', record.id);
		this.isNew = !record.id;
		this.isSaving = true;
	};

	onSave = (record) => {
		this.isSaving = false;
		if (this.isNew) {
			this.props.history.push(`/projects/${record.id}`);
		}
	};
	
	back = () => {
		this.props.history.push(`/projects`);
	};

	onError = error => {
		this.error = error.message;
	};
	
	processText = html => {
		if (html) {
			const match = html.match(/<img[^>]+>/ig);
			if (match) {
				match.forEach(imgTag => {
					const srcMatch = imgTag.match(/src="([^"]+)"/i);
					if (srcMatch && srcMatch[1]) {
						const src = srcMatch[1];
						console.log('-', src);
						const re = new RegExp(`(${imgTag})`, 'g');
						html = html.replace(re, `<a class="image-link" target="_blank" href="${src}">$1</a>`);
					}
				});
			}
		}
		return html;
	}
	
	labelTag = label => <span className="label-tag">
		<span className="color" style={{ background: label.color }}/> {label.name}
	</span>;

	uploadImages = () => new Promise((resolve, reject) => {
		this.editor.uploadImages(success => {
			if (success) {
				resolve(success);
			}
			else {
				reject();
			}
		});
	});

	renderProjectList = boardList => <><span className="color" style={{ background: boardList.color }} /> {boardList.name}</>;

	render () {
		if (this.isLoading) return '...';

		const canWrite = store.model.Project.INFO.WRITE;
		const canDelete = (this.record && store.model.user.id === this.record.ownerId) || this.roles.includes('admin');

		const controls = canDelete && this.record && this.record.id ?
			<>
				<ProjectDelete project={this.record} />
				{this.isSaving && <Loader size={18} style={{ marginLeft: 15 }} />}
			</>
			:
			null;

		return !this.isLoading && <div className="project-edit fixed-page">
			{this.record && <>
				<Form record={this.record} stay noDelete onError={this.onError} beforeSave={this.beforeSave} onSave={this.onSave} controls={controls}>
					<div className="row">
						<div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
							<Field property="name" label={t('name')} disabled={!canWrite} />
						</div>
						<div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
							<Field relation="projectList" property="name" label={t('project.projectList')}>
								<RelationSelect
									relation="projectList"
									property="name"
									computed={this.renderProjectList}
									className="drop-down-md"
									isRequired
								/>
							</Field>
						</div>
					</div>
					<div className="project-owner form-field">
						<label>{t('project.owner')}</label>
						{this.record.owner ? <User user={this.record.owner} /> : null} {relative(this.record.createdAt)}
					</div>
					{this.record.id && <div className="description">
						{this.editDescription ?
							<Editor
								key={this.record.id || 'new'}
								value={this.record.description}
								onChange={this.onDescriptionChange}
								mediaModel={store.model.Attachment}
								onInit={this.onEditorInit}
								height={window.innerWidth < 768 ? 200 : 600}
								disabled={!canWrite}
							/>
							:
							(this.record && this.record.description && <div className="description-text" dangerouslySetInnerHTML={{ __html: this.processText(this.record.description) }}/>)
						}
						{canWrite && <div className="toggle-edit">
							<Button onClick={this.switchDescription} variant="primary" size="small">
								{t(this.editDescription ? 'save' : (this.record.description ? 'edit' : 'task.createDescription'))}
							</Button>
						</div>}
					</div>}
				</Form>
				{this.record.id && <div className="form-field">
					<label>{t('label.plural')}</label>
					<LabelsEdit record={this.record} readOnly={!canWrite} />
				</div>}
				{this.record.id && <Comments title={t('project.history')} record={this.record} />}
			</>}
			{this.error && <div className="error">{this.error}</div>}
		</div>;
	}
}
