import React from 'react';

import { Column } from '@smartplatform/ui';
import { ModelList } from 'components';
import store from 'client/store';
import t from 'i18n';

const filter = {
	include: [
		{
			relation: 'priceCategoryFeatures',
			scope: {
				include: [
					{ relation: 'category', scope: { fields: ['id', 'name'] }},
				],
				order: 'id asc',
			}
		}
	],
};

const renderCategories = record => record ?
	record.priceCategoryFeatures().filter(r => !!r.category).map(r => r.category.name).join(', ')
	:
	'-';

export default ({ modelName, path }) => {
	return <ModelList model={store.model.Feature} path={path} title={t('feature.plural')} filter={filter}>
		<Column property="name" label={t('name')} />
		<Column label={t('feature.products')} computed={renderCategories} />
		<Column width={100} property="type" label={t('feature.type')} computed={record => t(`feature.${record.type}`)} />
	</ModelList>;
};
