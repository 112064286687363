import { Field } from '@smartplatform/ui';
import store from 'client/store';
import { ModelEdit } from 'components';
import t from 'i18n';
import React from 'react';


export default ({modelName, path, match, title, relation, relationLabel}) => {
	const id = parseInt(match.params.id);
	return <ModelEdit model={store.model[modelName]} path={path} id={id} title={title} filter={{include: [relation]}}>
		<Field property="name" label={t('name')}/>
		<Field relation={relation} property="name" label={t(relationLabel)}/>
	</ModelEdit>;
};
