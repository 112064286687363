import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { Table, Column, NumberValue, Button, Pager, Loader } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';

const PER_PAGE = 10;

@observer
export default class MatchingPositions extends React.Component {

	@observable priceCategoryFeatures = [];
	@observable pricePositions = [];
	@observable isInitialized = false;
	@observable isLoading = true;
	@observable page = 1;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		const { category } = this.props;
		this.priceCategoryFeatures = await category.priceCategoryFeatures.find({
			where: { featureId: { neq: null }},
			order: 'weight asc',
		});
		await this.load();
		this.isInitialized = true;
	};

	load = async () => {
		const { category, orderPosition, orderPositionValues } = this.props;

		this.isLoading = true;
		this.pricePositions = await store.model.PriceCategory.findMatches({
			categoryId: category.id,
			amount: orderPosition.amount,
			orderPositionValues,
			skip: (this.page - 1) * PER_PAGE,
			limit: PER_PAGE,
		});
		this.isLoading = false;
	};

	onPageChange = page => {
		this.page = page;
		this.load();
	};

	renderPositionValue = (params, priceCategoryFeature) => {
		const { relation: values } = params;
		const feature = priceCategoryFeature.feature;
		const property = feature.type === 'number' ? 'numberValue' : feature.type === 'boolean' ? 'booleanValue' : 'stringValue';
		const pricePositionValue = values.find(v => v.featureId === feature.id);
		if (!pricePositionValue) return '?';

		let value = pricePositionValue[property];
		if (feature.type === 'boolean') {
			value = value === true ? t('yes') : '-';
		}

		// console.log('>', pricePositionValue);
		return value;
	};

	render() {
		if (!this.isInitialized) return <div className="loading-popover"><Loader size={20} /></div>;

		const { orderPosition, onSelect, total } = this.props;

		const disabled = !parseInt(orderPosition.amount);

		return <div className="matching-positions">
			<Table rows={this.pricePositions} className={total > PER_PAGE ? 'full' : ''}>
				{this.priceCategoryFeatures.map(priceCategoryFeature => {
					// console.log('priceCategoryFeature', priceCategoryFeature);
					return <Column key={priceCategoryFeature.id} relation="values" label={priceCategoryFeature.feature.name} className="text-center">
						{position => this.renderPositionValue(position, priceCategoryFeature)}
					</Column>
				})}
				<Column property="amountFrom" label={t('pricePosition.from')} className="text-right"><NumberValue /></Column>
				<Column property="amountTo" label={t('pricePosition.to')} className="text-right"><NumberValue /></Column>
				<Column property="price" label={t('pricePosition.priceForOne')} className="text-right"><NumberValue type="currency" /></Column>
				{!disabled && <Column label={t('pricePosition.total')} className="text-right">
					{({ record: position }) => <NumberValue type="currency" value={position.price * orderPosition.amount} />}
				</Column>}
				{!disabled && <Column>
					{({ record: position }) =>
						<Button variant="primary" size="small" onClick={() => onSelect(position)} disabled={disabled}>{t('pricePosition.copyPrice')}</Button>
					}
				</Column>}
			</Table>
			{total > PER_PAGE && <Pager current={this.page} onChange={this.onPageChange} itemsPerPage={PER_PAGE} totalCount={total}/>}
			{disabled && <div className="hint">{t('orderPosition.emptyAmount')}</div>}
		</div>;
	}

}
