import React from 'react';
import store from 'client/store';
import { DEFAULT_PRIORITY } from '../kanban/OrdersKanban';
import t from 'i18n';
import { Button, Loader } from '@smartplatform/ui';
import { copy } from 'superagent';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export default class CopyOrder extends React.Component {
    @observable isLoading = false;

    constructor(props) {
        super(props);
        this.store = props.store;
    }

    copyOrder = async () => {
        this.isLoading = true;
        //* копируем в новый заказ контрагента и состав текущего
        const { order } = this.props;
        //* еще раз зафетчим заказ, чтобы не сбивались кэш-данные реляций
        const currentOrder = await store.model.Order.findById(order.id, {
            include: [
                { relation: 'contacts' },
                {
                    relation: 'positions',
                    scope: {
                        include: [{ relation: 'values' }, { relation: 'parameters' }],
                    },
                },
            ],
        });

        //* получаем дефолтный лист и приоритет для нового заказа
        let defaultList;
        let maxPriority;
        try {
            defaultList = await store.model.OrderList.find({
                where: { default: true },
                include: { relation: 'orders', fields: ['priority'], order: 'priority desc', limit: 1 },
                limit: 1,
            });
            defaultList = defaultList.length > 0 ? defaultList[0] : null;
            maxPriority = DEFAULT_PRIORITY;
            if (defaultList) {
                const orders = defaultList.orders();
                const maxPriorityOrder = orders.length > 0 ? orders[0] : null;
                if (maxPriorityOrder) {
                    maxPriority = maxPriorityOrder.priority * 2;
                }
            } else {
                store.ui.setNotification({ message: t('order.defaultListError'), error: true });
                return;
            }
        } catch (e) {
            console.error('fetching default order list error: ', e);
        }

        //* создаем копию заказа
        let copy;
        try {
            copy = new store.model.Order();
            copy.priority = maxPriority;
            copy.description = currentOrder.description;
            copy.listId = defaultList ? defaultList.id : 1;
            copy.client = currentOrder.client;
            await copy.save();
        } catch (e) {
            console.error('Saving copy order error:', e);
        }

        //* создаем записи контактных лиц
        try {
            const contacts = currentOrder.contacts();
            for (let contact of contacts) {
                await copy.contacts.add(contact.id);
            }
        } catch (e) {
            console.error('Add contacts to copy order error', e);
        }

        //* создаём записи позиций
        try {
            const positions = currentOrder.positions();
            for (let position of positions) {
                const values = position.values();
                const parameters = position.parameters();

                const { id, orderId, createdAt, updatedAt, _attachments, _parameters, _values, _members, _category, ...rest } = position;
                const copyPosition = new store.model.OrderPosition({
                    orderId: copy.id,
                    ...rest,
                });
                await copyPosition.save();

                //* характеристики внутри позиции
                try {
                    for (let value of values) {
                        const { id, positionId, createdAt, updatedAt, ...rest } = value;
                        const copyValue = new store.model.OrderPositionValue({
                            positionId: copyPosition.id,
                            ...rest,
                        });
                        await copyValue.save();
                    }
                } catch (e) {
                    console.error('Add values to copy position: ', e);
                }

                try {
                    for (let parameter of parameters) {
                        const { id, positionId, createdAt, updatedAt, ...rest } = parameter;
                        const copyParameter = new store.model.OrderPositionParameterValue({
                            positionId: copyPosition.id,
                            ...rest,
                        });
                        await copyParameter.save();
                    }
                } catch (e) {
                    console.error('Add parameters to copy position error:', e);
                }
            }
        } catch (e) {
            console.error('Add positions to copy order error: ', e);
        }
        this.isLoading = false;
        store.route.push({ path: `/orders/${copy.id}` });
    };

    render() {
        return (
            <Button onClick={this.copyOrder} variant="primary" size="sm">
                {t('order.copy')} {this.isLoading && <Loader size={12} />}
            </Button>
        );
    }
}
