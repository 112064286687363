import React from 'react';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';

import {
	Form,
	Field,
	Select
} from '@smartplatform/ui';
import store from 'client/store';
import { fio } from 'client/tools';
import t from 'i18n';
import data from './data';

@observer
class RoleMappingEdit extends React.Component {
	
	@observable record = null;
	@observable users = null;
	@observable isLoading = false;
	
	constructor (props) {
		super(props);
		store.ui.title = t('roleMapping.title');
		this.init();
	}
	
	@action init = async () => {
		this.isLoading = true;
		
		if (this.props.match.params.id) {
			this.record = await store.model.RoleMapping.findById(this.props.match.params.id, {
				include: ['role'],
			});
		} else {
			this.record = new store.model.RoleMapping();
		}
		
		this.users = await store.model.User.find();
		this.isLoading = false;
	};
	
	back = () => {
		store.route.push({ path: `/rolemappings` });
	};
	
	render () {
		if (this.isLoading) return '...';
		
		const users = this.users.map(user => {
			return { value: '' + user.id, label: <>{fio(user)} {user.email && <>({user.email})</>}</> };
		});
		
		return <div className="fixed-page">
			<Form record={this.record} stay onDelete={this.back} onSave={this.back}>
				<Field property="principalType" label={t('acl.principalType')}>
					<Select items={data.PRINCIPAL_TYPE.map(pt => ({ value: pt.id, label: pt.name }))} />
				</Field>
				
				{this.record.principalType === 'USER' && this.users && (
					<Field property="principalId" label={t('user.title')}>
						<Select items={users} position="bottomLeft"/>
					</Field>
				)}
				
				<Field relation="role" property="name" label={t('role.title')} />
			</Form>
		</div>;
	}
}

export default RoleMappingEdit;
