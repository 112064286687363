import React, { useState } from 'react';
import { Field, Button } from '@smartplatform/ui';
import { ModelEdit } from 'components';
import store from 'client/store';



export default ({ modelName, path, match }) => {
	const id = parseInt(match.params.id);
	return <ModelEdit model={store.model[modelName]} path={path} id={id} title="Журнал синхронизации" disabled noDelete stay>
		<div className='row'>
			<div className='col-2'>
				<Field property="updatedAt" label="Дата и время" />
			</div>
			<div className='col-10'>
				<Field property='name' label='Имя файла'/>
			</div>
		</div>
		<Field property='name'>
			<FileLoad />
		</Field>
		<Field property='id'>
			<Reimport />
		</Field>
	</ModelEdit>;
};
const FileLoad = ({ value }) => {
	return <a href={`/api/containers/edfiles/download/${value}`} download>{value}</a>
}
const Reimport = (props) => {
	const [ isLoad, setLoad ] = useState(false);
	const { value } = props;
	const handleClick = async () => {
		setLoad(true);
		console.log(props)
		await store.model.EdFile.reimportData(value);
		setLoad(false);
	};
	return (
		<Button
			disabled={isLoad}
			onClick={handleClick}
		>
			Откатиться до этой версии
		</Button>
	);
}