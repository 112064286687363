import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, RecordSelect } from '@smartplatform/ui';
import { fio } from 'client/tools';
import { UserCard } from 'components';
import NewUser from './NewUser';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

const NEW_CONTACT = 'NEW_CONTACT';

@observer
export default class Contacts extends React.Component {

	@observable contacts = [];
	@observable newContact = null;
	@observable newUser = false;
	@observable removeMessage = t('client.removeContact');

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.contacts = await this.props.client.contacts.find({
			fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'phone', 'email', 'avatar'],
			order: 'id asc',
		});
	};

	onNewContactChange = user => {
		if (user === NEW_CONTACT) {
			console.log(NEW_CONTACT);
			this.newUser = true;
		}
		else {
			this.newContact = user;
		}
	};

	onClose = async (updated) => {
		this.newUser = false;
		if (updated) await this.init();
	};

	beforeDelete = async (user) => {
		const orders = await store.model.OrderContact.find({
			where: { userId: user.id },
			scope: ['id'],
		});
		console.log('orders', orders.length);
		this.removeMessage = orders.length > 0 ? `${t('client.removeContactWarning')} ${orders.length}.` : t('client.removeContact');
	};

	deleteContact = async (user) => {
		const orders = await store.model.OrderContact.find({
			where: { userId: user.id },
			scope: ['id'],
		});
		await Promise.all(orders.map(o => o.delete()));
		await this.props.client.contacts.remove(user.id);
		await this.init();
	};

	addContact = async () => {
		await this.props.client.contacts.add(this.newContact.id);
		this.newContact = null;
		await this.init();
	};

	gotoUser = user => store.route.push({ path: `/users/${user.id}` });

	render() {
		return <div className="client-contacts">
			<div className="form-field">
				<label>{t('client.contacts')}</label>
				{this.contacts.map(user => {
					return <UserCard
						noDefaultEmail
						key={user.id}
						user={user}
						onRemove={this.deleteContact}
						onRemoveClick={() => this.beforeDelete(user)}
						confirmMessage={this.removeMessage}
					/>
				})}
			</div>
			<div className="new-contact">
				<RecordSelect
					model={store.model.User}
					computed={fio}
					value={this.newContact}
					onChange={this.onNewContactChange}
					placeholder={t('contact.add')}
					searchPlaceholder={t('contact.searchHint')}
					itemsPerPage={8}
					filter={{
						where: { id: { nin: this.contacts.map(c => c.id) } },
					}}
					listClassName="new-contact-select"
					appendItems={[
						{ label: t('contact.new'), value: NEW_CONTACT, className: 'new-user' },
					]}
					isRequired
				/>
				<Button onClick={this.addContact} variant="primary" disabled={!this.newContact}>{t('client.addContact')}</Button>
			</div>
			{this.newUser && <NewUser onClose={this.onClose} client={this.props.client} />}
		</div>;
	}

}
