import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Checkbox, DatePicker, Input, RecordSelect, Row } from '@smartplatform/ui';
import { Editable, Section, User, Attachments } from 'components';
import store from 'client/store';
import { DEFAULT_PRIORITY } from '../kanban/OrdersKanban';
import t from 'i18n';
import CopyOrder from './CopyOrder';

@observer
export default class MainEdit extends React.Component {

	@observable editField = null;

	constructor(props) {
		super(props);
		this.store = props.store;
	}

	onDescriptionChange = (value) => {
		this.store.order.description = value;
	};

	onCommentChange = (value) => {
		this.store.order.comment = value;
	};
	onGarantChange = (value) => {
		this.store.order.garant = value;
	}

	onInvoiceNumberChange = async (value) => {
		this.store.order.invoiceNumber = value;
	};

	onInvoiceDateChange = async (value) => {
		this.store.order.invoiceDate = value;
		await this.save();
	};

	onContractNumberChange = async (value) => {
		this.store.order.contractNumber = value;
	};

	onContractDateChange = async (value) => {
		this.store.order.contractDate = value;
		await this.save();
	};

	onStartDateChange = async (value) => {
		this.store.order.startDate = value;
		await this.save();
	};

	onEndDateChange = async (value) => {
		this.store.order.endDate = value;
		await this.save();
	};

	onManagerChange = async (value) => {
		this.store.order.manager = value;
		await this.save();
	};

	onListChange = async (list) => {
		this.store.order.list = list;
		const topOrder = await store.model.Order.find({
			where: {
				listId: list.id,
			},
			fields: ['id', 'priority'],
			order: 'priority desc',
			limit: 1,
		});
		console.log('topOrder', topOrder);
		this.store.order.priority = topOrder.length > 0 ? topOrder.priority * 2 : DEFAULT_PRIORITY;
		await this.save();
	};

	onPaidChange = async (value) => {
		this.store.order.paid = value;
		await this.save();
	};

	// без parseFloat в хук beforesave попадет string и значение не пройдет проверку
	// с числом,в результате чего можно заносить в логи одни и те же значения
	onPrepaidChange = (value) => this.store.order.prepaid = Boolean(value);

	trimValues = () => {
		const { order } = this.store;
		const fields = [
			'description',
			'invoiceNumber',
			'contractNumber',
		];
		fields.forEach(field => {
			if (typeof order[field] === 'string') order[field] = order[field].trim();
		});
	};

	save = async () => {
		this.trimValues();
		await this.store.save();
	};

	renderClient = client => client.name;

	renderUser = user => <User user={user} />;

	renderList = list => <><span className="color" style={{ background: list.color }} /> {list.name}</>;

	onAttachmentDelete = async (attachment) => {
		if (attachment) {

		}
	};
	onAttachmentChange = async (attachments) => {
		if (attachments && (attachments.length > 0)) {

		}
	}

	render() {
		const { order, canViewSum } = this.store;
		const { availableListIds,  } = this.props;
		//? возможно потом документы будут видеть только отдельные роли
		const canViewDoc = canViewSum;

		return <Section className="order-main-edit">
			<header>
				<div className='w-100 d-flex justify-content-between align-items-center'>
					<div>{t('order.info')}</div>
					<CopyOrder order={order} />
				</div>
			</header>

			<div className="block">
				<Editable
					value={order.description}
					onChange={this.onDescriptionChange}
					width={300}
					placeholder={t('order.addDescription')}
					onSubmit={this.save}
				/>
			</div>

			<Row>
				<div className="form-field">
					<label>{t('order.invoiceNumber')}: </label>
					<Input
						value={order.invoiceNumber}
						onChange={this.onInvoiceNumberChange}
						width={100}
						placeholder="-"
						onBlur={this.save}
					/>
				</div>
				<div className="form-field">
					<label>{t('order.invoiceDate')}: </label>
					<DatePicker
						value={order.invoiceDate}
						onChange={this.onInvoiceDateChange}
						placeholder="-"
						format="dd.MM.yyyy"
					/>
				</div>
				<div className="form-field">
					<label>{t('order.contractNumber')}: </label>
					<Input
						value={order.contractNumber}
						onChange={this.onContractNumberChange}
						width={100}
						placeholder="-"
						onBlur={this.save}
					/>
				</div>
				<div className="form-field">
					<label>{t('order.contractDate')}: </label>
					<DatePicker
						value={order.contractDate}
						onChange={this.onContractDateChange}
						placeholder="-"
						showTimeSelect={false}
						format="dd.MM.yyyy"
					/>
				</div>
			</Row>

			<Row>
				<div className="form-field">
					<label>{t('order.status')}</label>
					<RecordSelect
						model={store.model.OrderList}
						value={order.list}
						onChange={this.onListChange}
						computed={this.renderList}
						filter={{ where: canViewSum ? undefined : { id: { inq: availableListIds } } }}
						isRequired
					/>
				</div>
				<Row>
					<div className="form-field">
						<label>{t('order.startDate')}: </label>
						<DatePicker
							value={order.startDate}
							onChange={this.onStartDateChange}
							placeholder="-"
							showTimeSelect={false}
							format="dd.MM.yyyy"
						/>
					</div>
					<div className="form-field">
						<label>{t('order.endDate')}: </label>
						<DatePicker
							value={order.endDate}
							onChange={this.onEndDateChange}
							placeholder="-"
							showTimeSelect={false}
							format="dd.MM.yyyy"
						/>
					</div>
				</Row>
			</Row>

			<Row>
				<div className="form-field">
					<label>{t('order.manager')}</label>
					<RecordSelect
						model={store.model.User}
						value={order.manager}
						onChange={this.onManagerChange}
						computed={this.renderUser}
					/>
				</div>
				<Row>
					<div className="form-field">
						<label>{t('order.prepaid')}</label>
						<div className="paid">
							<Checkbox value={order.prepaid} onChange={this.onPrepaidChange} label={t('order.getprepaid')} />
						</div>
					</div>
					<div className="form-field">
						<label>{t('order.paid')}</label>
						<div className="paid">
							<Checkbox value={order.paid} onChange={this.onPaidChange} label={t('order.paid')} />
						</div>
					</div>
				</Row>
			</Row>

			<Row>
		
				<div className="form-field">
					<label>{t('order.comment')}</label>
					<Editable
						rows={3}
						value={order.comment}
						onChange={this.onCommentChange}
						width="100%"
						placeholder={t('order.addComment')}
						onSubmit={this.save}
					/>
				</div>
				<div className="form-field">
					<label>{t('order.garant')}</label>
					<Editable
						rows={3}
						value={order.garant}
						onChange={this.onGarantChange}
						width="100%"
						placeholder={t('order.addGarant')}
						onSubmit={this.save}
					/>
				</div>
				{canViewDoc && <div className="form-field">
					<label>{t('order.document')}</label>
					<Attachments record={order} canUpload onDelete={this.onAttachmentDelete} onChange={this.onAttachmentChange} />
				</div>}
			</Row>
		</Section>;
	}

}
