import { Column } from '@smartplatform/ui';
import store from 'client/store';
import { ModelList } from 'components';
import t from 'i18n';
import React from 'react';


export default ({modelName, path, title}) => {
    const renderCount = (record) => record.length;
    return <ModelList model={store.model[modelName]} path={path} title={title} filter={{include:['operations' , 'equipments']}}>
        <Column property="name" label={t('name')}/>
        <Column relation="operations" computed={renderCount}  label={t('operation.count')}/>
        <Column relation="equipments" computed={renderCount}  label={t('equipment.count')}/>
    </ModelList>;
};
