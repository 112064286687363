import React from 'react';
import { observer } from 'mobx-react';
import { Link, matchPath } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import store from 'client/store';

@observer
export default class MenuItem extends React.Component {
	
	render() {
		const { item } = this.props;
		const { label, path, icon } = item;
		
		if (item.enabled && !item.enabled(store)) return null;

		const match = matchPath(document.location.pathname, { path });
		const isActive = match !== null;
		
		const className = classNames('item', { active: isActive });
		
		return <Link to={path} className={className}>
			<span className="icon">{icon ? <FontAwesomeIcon icon={icon} /> : <span className="no-icon" /> }</span>
			<div className="title">{label}</div>
		</Link>;
	}
	
}
