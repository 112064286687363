import React from 'react';
import { observable, toJS } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Pager, Table, Column, NumberValue, BooleanValue, DateValue } from '@smartplatform/ui';
import { User } from 'components';
import store from 'client/store';
import { renderList } from '..';
import t from 'i18n';
import './style.scss';

const ORDER_INCLUDE = [
	{ relation: 'list', scope: { fields: ['id', 'color', 'name'] } },
	{ relation: 'client', scope: { fields: ['id', 'name'] } },
	{ relation: 'manager', scope: { fields: ['id', 'lastName', 'firstName', 'middleName', 'username', 'avatar'] } },
	{
		relation: 'positions',
		scope: {
			where: { categoryId: { neq: null } },
			include: [
				{ relation: 'category', scope: { fields: ['id', 'name'] } }
			],
			order: 'id asc',
		}
	},
	{
		relation: 'labels',
		scope: {
			fields: ['id', 'name', 'color']
		}
	},
];

@inject('store') @observer
export default class OrdersList extends React.Component {

	@observable query = {};
	@observable pageSize = 50;
	@observable page = 1;
	@observable totalCount = 0;

	constructor(props) {
		super(props);
		this.store = props.store;
		props.store.reload = this.reload;
		this.query = {
			where: this.store.compileFilter(this.store.availableListIds),
			include: ORDER_INCLUDE,
			skip: 0,
			limit: this.pageSize,
			order: 'id desc',
		};
	}

	getInstance = instance => this.table = instance;

	onQueryUpdate = query => {
		const { _totalCount, ...rest } = query;
		this.query = rest;
		this.totalCount = _totalCount;
		// console.log('onQueryUpdate', _totalCount, JSON.stringify(toJS(query.where)));
	};

	reload = () => {
		this.query = {
			...this.query,
			where: this.store.compileFilter(),
			skip: 0,
		};
		// if (this.table) this.table.reload();
	};

	onPageChange = page => {
		this.page = page;
		this.query = {
			...this.query,
			skip: (this.page - 1) * this.pageSize,
		};
	};

	gotoOrder = (order) => {
		console.log('gotoOrder', order);
		store.route.push({ path: `/orders/${order.id}` });
	};

	renderPositions = (order) => order.positions().length;

	renderUser = (user) => <User user={user} />;
	
	renderTotal = (record) => record.total ? 
		<div>
			<NumberValue type="currency" value={record.total} />
			{record.paid && <div>{t('order.paid')}</div>}
		</div> 
	: '-';

	renderCategories = (positions) => {
		if (positions.length > 0) {
			const categoryNames = [...new Set(positions.map(pos => pos.category?.name))];
			return <div>{categoryNames.map((name,i) => <div key={i}>{name}</div>)}</div>
		}
	}

	render() {
		const { canViewSum } = this.props.store;

		return <div className="orders-list">
			<Pager itemsPerPage={this.pageSize} current={this.page} onChange={this.onPageChange} totalCount={this.totalCount} />
			<Table model={store.model.ViewOrder} filters={false} query={this.query} onQueryUpdate={this.onQueryUpdate} onRowClick={this.gotoOrder} getInstance={this.getInstance}>
				<Column width={60} property="id" label={t('order.n')} className="text-center" />
				<Column property="clientName" label={t('client.title')} />
				{canViewSum && <Column width={100} property="total" computed={this.renderTotal} label={t('order.price')} className="text-right" />}
				<Column relation="list" computed={renderList} label={t('order.status')} />
				{/* <Column width={80} property="paid" label={t('order.paid')} className="text-center"><BooleanValue isFalse="-" /></Column> */}
				<Column relation="manager" computed={this.renderUser} label={t('order.manager')} />
				<Column width={150} relation="positions" computed={this.renderCategories} label={t('order.category')} />
				<Column width={90} property="invoiceNumber" label={t('order.invoiceNumber')} />
				<Column width={90} property="contractNumber" label={t('order.contractNumber')} />
				<Column width={85} property="startDate" label={t('order.startDate')}><DateValue format="dd.MM.yyyy" /></Column>
				<Column width={85} property="endDate" label={t('order.endDate')}><DateValue format="dd.MM.yyyy" /></Column>
			</Table>
		</div>;
	}

}
