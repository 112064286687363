import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Loader } from '@smartplatform/ui';
import Attachment from './Attachment';
import store from 'client/store';
import t from 'i18n';
import './style.scss';

const bytesToSize = (bytes) => {
	var sizes = [t('bytes'), t('Kb'), t('Mb'), t('Gb'), t('Tb')];
	if (bytes === 0) return '0 ' + sizes[0];
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
}

@observer
export default class Attachments extends React.Component {

	static propTypes = {
		record: PropTypes.object.isRequired,
		relation: PropTypes.string,
		property: PropTypes.string,
		canUpload: PropTypes.bool,
		onChange: PropTypes.func,
		onDelete: PropTypes.func,
	};
	
	static defaultProps = {
		relation: 'attachments',
		property: 'filename',
		canUpload: false,
	};
	
	@observable attachments = [];
	@observable newFiles = [];
	@observable errors = [];
	@observable isUploading = false;
	
	constructor(props) {
		super(props);
		this.id = this.props.record.MODEL.name + '-' + this.props.record.id;
		const modelName = this.props.record.MODEL.RELATIONS[this.props.relation].model;
		this.model = store.model[modelName];
		this.init();
	}
	
	init = async (reload = false) => {
		const filter = {
			where: {
				or: [
					{ inline: null },
					{ inline: false },
				],
			}
		};
		const attachments = this.props.record[this.props.relation].find(filter);
		if (!reload) {
			this.attachments = attachments.then ? await attachments : attachments;
		}
		else {
			this.attachments = await this.props.record[this.props.relation].find(filter);
		}
		console.log('attachments', this.attachments.length);
	};
	
	onAttach = async (e) => {
		console.log('onAttach', e.target.files);
		const newFiles = [];
		this.errors = [];
		for (let file of e.target.files) {
			const attachment = new this.model();
			attachment[this.props.property] = file.name;
			newFiles.push({
				attachment,
				file,
				uploadProgress: 0,
				done: false,
				error: null,
			});
		}
		this.newFiles = newFiles;
		this.isUploading = true;
		const promises = this.newFiles.map(this.upload);
		await Promise.all(promises);
		this.props.onChange && this.props.onChange(newFiles);
		this.isUploading = false;
		this.errors = this.newFiles.filter(uploadObj => !!uploadObj.error);
		this.newFiles = [];
		await this.init(true);
	};
	
	handleFileProgress = (uploadObj, event) => {
		// console.log('handleFileProgress', event.percent, event);
		uploadObj.uploadProgress = event.percent;
	};
	
	upload = async (uploadObj) => {
		await uploadObj.attachment.save();
		try {
			const res = await this.uploadFile(uploadObj);
			uploadObj.done = true;
			await this.props.record[this.props.relation].add(uploadObj.attachment.id);
		}
		catch (error) {
			// console.log('status', error.status, error.status === 413, typeof error.status);
			if (error.status === 413) {
				uploadObj.error = t('file.fileTooBig');
			}
			else {
				uploadObj.error = t('file.uploadError');
			}
			await uploadObj.attachment.delete();
		}
	};
	
	uploadFile = (uploadObj) => new Promise((resolve, reject) => {
		uploadObj.attachment.uploadFile(this.props.property, uploadObj.file)
			.on('progress', (event) => this.handleFileProgress(uploadObj, event))
			.end((error, result) => {
				console.log({ error, result });
				if (!error) {
					resolve(result);
				}
				else {
					reject(error);
				}
			});
	});

	onDelete = async (attachment) => {
		const index = this.attachments.findIndex(_attachment => _attachment.id === attachment.id);
		console.log('onDelete', attachment, index);
		if (index !== -1) this.attachments.splice(index, 1);
		this.props.onChange && this.props.onChange();
		this.props.onDelete && this.props.onDelete(attachment);
	};
	
	render() {
		return <div className="attachments">
			{this.attachments.length > 0 && <div className="list">
				{this.attachments.map(attachment => <Attachment key={attachment.id} attachment={attachment} onDelete={this.onDelete} />)}
			</div>}
{/*
			<div className="new-files">
				<div className="new-file">
					<div className="icon"><Loader size={14} /></div>
					<div className="info">
						<div className="file-name">uploading_file1</div>
						<div className="progress">{t('uploading')}: <em>{Math.round(78.12) + '%'}</em> из <em>{bytesToSize(50000)}</em></div>
					</div>
				</div>
				<div className="new-file">
					<div className="icon"><FontAwesomeIcon icon={faCheck} /></div>
					<div className="info">
						<div className="file-name">uploaded_file2</div>
						<div className="progress">{t('uploaded')}</div>
					</div>
				</div>
			</div>
*/}
			{this.isUploading && <div className="new-files">
				{this.newFiles.map((uploadObj, i) => {
					return (uploadObj.done && !uploadObj.error) ?
						<Attachment key={uploadObj.attachment.id} attachment={uploadObj.attachment} isNew />
						:
						<div key={i} className="new-file">
							<div className="icon"><Loader size={14} /></div>
							<div className="info">
								<div className="file-name">{uploadObj.file.name}</div>
								{uploadObj.uploadProgress !== undefined && <div className="progress">{t('file.uploading')}: <em>{Math.round(uploadObj.uploadProgress) + '%'}</em> из <em>{bytesToSize(uploadObj.file.size)}</em></div>}
							</div>
						</div>;
				})}
			</div>}

			{this.errors.length > 0 && <div className="upload-errors">{this.errors.map((uploadObj, i) => {
				return <div key={i} className="new-file upload-error">
						<div className="icon"><FontAwesomeIcon icon={faExclamationTriangle}/></div>
						<div className="info">
							<div className="file-name">{uploadObj.file.name}</div>
							<div className="error-msg">{uploadObj.error}</div>
						</div>
					</div>;
			})}</div>}

			{this.props.canUpload && !this.isUploading && <div className="upload">
				<input type="file" id={this.id} onChange={this.onAttach} multiple />
				<label htmlFor={this.id}>{t('uploadFiles')}</label>
			</div>}
		</div>;
	}
	
}
