import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import store from 'client/store';
import { relative } from 'client/tools';
import t from 'i18n';

const stopPropagation = e => e.stopPropagation();

@observer
export default class TaskItem extends React.Component {

	constructor(props) {
		super(props);
	}
	
	onClick = () => store.route.push({ path: `/tasks/${this.props.task.id}` });
	
	render() {
		const { task } = this.props;
		
		const className = classNames('task-item', {
		
		});
		
		return <div className={className} onClick={this.onClick} style={{ borderLeftColor: task.boardList && task.boardList.color || '#ccc' }}>
			<div className="top"><em>#{task.id}</em> {task.name}</div>
			<div className="bottom">
				<span className="list">{task.boardList && task.boardList.name}</span>
				{task.project ?
					<Link to={`/projects/${task.projectId}`} onClick={stopPropagation}>{task.project.name}</Link>
					:
					<div className="error"><FontAwesomeIcon icon={faExclamationTriangle} /> {t('task.noProject')}</div>
				}
				<div className="updatedAt"><span>{(t('task.updatedAt'))}:</span><em>{relative(task.updatedAt)}</em></div>
			</div>
		</div>;
	}
	
}
