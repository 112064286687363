import React, {useEffect, useState} from "react";
import { Switch, Route } from 'react-router-dom';
import store from 'client/store';
import List from './List';
import './style.scss';
import { NomenclatureGroups } from "components";
const path = '/nomenclature';
const modelName = 'Nomenclature';

const MAX_QUERY_DEPTH = 10;
const getChildrenRelation = (depth = 0) => {
    if(depth >= MAX_QUERY_DEPTH)
        return({});
    return({
        include: {
            relation: 'children',
            scope: getChildrenRelation(depth + 1)
        }
    });
}
const query = {
    where: {
        parentId: null
    },
    ...getChildrenRelation()
}
export const Nomenclature = () => {
    store.ui.title = "Номенклатура";
    const [ groups, setGroups ] = useState([]);
    const [ groupsFilter, setGroupsFilter ] = useState([]);
    const init = async () => {
        const result = await store.model.NomenclatureGroup.find(query);
        setGroups(result);
    }
    const handleGroupsFilter = (group) => {
        setGroupsFilter(prev => {
            const inFilter = prev.includes(group.id);
            if(inFilter)
                return Array.from(prev.filter(el => el !== group.id));
            else
                return Array.from([...prev, group.id]);
        })
    }
    useEffect(() => {
        init();
    }, []);
    return (
        <div className={`nomenclature-layout`}>
            <div className={`nomenclature-layout__groups`}>
                <NomenclatureGroups
                    groups={groups}
                    active={groupsFilter}
                    onFilter={handleGroupsFilter}
                />
            </div>
            <div className={`nomenclature-layout__content`}>
                <Switch>
                    <Route path={path} exact>
                        <List
                            modelName={modelName}
                            groupsFilter={groupsFilter}
                        />
                    </Route>
                    <Route path={`${path}/page/:page`}>
                        <List
                            modelName={modelName}
                            groupsFilter={groupsFilter}
                        />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};
