import React from 'react';

import { Column, NumberValue } from '@smartplatform/ui';
import { ModelList, User } from 'components';
import store from 'client/store';
import t from 'i18n';

export default ({ modelName, path, filter, match, title, type, pageSize }) => {
	const _filter = {
		include: [
			{ relation: 'person', _searchFields: ['lastName'] },
			{ relation: 'role', scope: { fields: ['id', 'name'] }, _searchFields: ['name']},
			{ relation: 'budget', scope: { fields: ['id', 'name'] }, _searchFields: ['name']},
		],
		...filter,
	};

	const props = { path, match, title, pageSize, filter: _filter };
	
	return <ModelList model={store.model[modelName]} {...props}>
		<Column width={60} property='id' label={t('id')} />
		{type !== 'incoming' && <Column width={120} relation="person" label={t('person.title')}>{user => <User user={user} />}</Column>}
		<Column property="sum" label={t('sum')} width={90} className="text-right"><NumberValue type="currency"/></Column>
		<Column width={120} property='date' label={t('payment.date')} />
		<Column relation="budget" property="name" label={t('budget.title')}/>
		{type !== 'incoming' && <Column relation="role" property="name" label={t('projectRole.title')}/>}
		<Column property="comment" label={t('comment.title')} />
	</ModelList>;
};
