import React from "react";
import store from 'client/store';
import { ModelList } from 'components';
import { Column } from '@smartplatform/ui';

const List = ({ modelName, path, groupsFilter = [] }) => {
    const filter = groupsFilter.length ? ({
        where: {
            groupId: {
                inq: groupsFilter
            }
        }
    }) : {};
    return (
        <ModelList
            model={store.model[modelName]}
            path={path}
            title="Номенклатура"
            disableCreate
            filter={filter}
        >
            <Column property="updatedAt" label="Дата последнего обновления" />
            <Column property="name" label="Имя продукта/материала" />
            <Column property="quantity" label="Количество"/>
            <Column property="measure" label="Ед. измерения"/>
        </ModelList>
    );
}

export default List;