import React from 'react';
import { observable } from 'mobx';
import { observer, Provider, inject } from 'mobx-react';
import { Loader, Tabs, Tab } from '@smartplatform/ui';
import { relative } from 'client/tools';
import Main from './Main';
import Positions from './order-positions';
const Passport = React.lazy(() => import('./passport/Passport'));
import OrderStore from './store';
import t from 'i18n';
import './style.scss';
import { TechnologyRoadmap } from './technology-roadmap';
import Waybill from './Waybill';




@inject('store') @observer
export default class Order extends React.Component {

	@observable error = null;
	@observable store = null;

	constructor(props) {
		super(props);
		this.init();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.match.params.id !== this.props.match.params.id) {
			this.init();
		}
	}

	init = async () => {
		this.error = null;
		this.store = new OrderStore();
		this.store.canViewSum = this.props.store.canViewSum;
		const id = this.props.match.params.id;
		try {
			await this.store.init(id);
		}
		catch (e) {
			this.error = e.message;
		}
	};

	render() {
		if (this.error) return <div className="error">{this.error}</div>;
		const { order } = this.store;
		const { canViewSum, availableListIds } = this.props.store;
		if (!order) return <div className="order-page"><Loader /></div>;

		return <Provider store={this.store}>
			<div className="order-page">
				<div className="order-title">
					<span className="order-number">{t('order.title')} #{order.id}. {order.description}</span>
					<span className="updated-at">{t('order.updatedAt')}: {relative(order.updatedAt)}</span>
				</div>
				<Tabs className="order-tabs" params={{ id: order.id }}>
					<Tab path="/orders/:id" exact title={t('order.info')}><Main availableListIds={availableListIds} /></Tab>
					{canViewSum && <Tab path="/orders/:id/positions" title={t('order.positions')}><Positions /></Tab>}
					<Tab path="/orders/:id/passport" title={'Паспорт заказа'}>
						<React.Suspense fallback={<div>Loading...</div>}>
							<Passport />
						</React.Suspense>
						</Tab>
					<Tab path="/orders/:id/technologyroadmap " title={t('order.technologyRoadmap')}><TechnologyRoadmap /></Tab>
					<Tab path="/orders/:id/waybill " title={t('order.waybill.tabname')}>
						<Waybill />
					</Tab>
				</Tabs>
			</div>
		</Provider>;
	}

}
