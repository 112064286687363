import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './List';
import Edit from './Edit';
import t from 'i18n';

const path = '/payments';
const modelName = 'Payment';

export const Payments = () => <Switch>
	<Route path={path} exact children={({ match }) => <List match={match} modelName={modelName} title={t('payment.plural')} />}/>
	<Route path={`${path}/page/:page`} children={({ match }) => <List match={match} modelName={modelName} title={t('payment.plural')} />}/>
	<Route path={`${path}/:paymentId`} children={({ match }) => <Edit match={match} path={path} modelName={modelName} title={t('payment.title')} />}/>
</Switch>;
