import React from 'react';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Button, RecordSelect } from '@smartplatform/ui';
import { User, UserCard } from 'components';
import store from 'client/store';
import t from 'i18n';

@observer
export default class MemberEdit extends React.Component {

	static propTypes = {
		member: PropTypes.object.isRequired,
		onSave: PropTypes.func.isRequired,
		onCancel: PropTypes.func.isRequired,
	};

	onUserChange = (user) => this.props.member.user = user;

	onRoleChange = (role) => this.props.member.role = role;

	save = async () => {
		await this.props.member.save();
		this.props.onSave();
	};

	cancel = async () => {
		this.props.onCancel();
	};

	render() {
		const filter = this.props.exclude.length > 0 ?
			{ where: { id: { nin: this.props.exclude }}}
			:
			undefined;

		return <div className="member-edit">
			<RecordSelect
				model={store.model.User}
				computed={user => <User user={user} />}
				value={this.props.member.user}
				filter={filter}
				onChange={this.onUserChange}
			/>
			<RecordSelect
				model={store.model.OrderPositionRole}
				property="name"
				value={this.props.member.role}
				onChange={this.onRoleChange}
			/>
			<div className="actions">
				<Button variant="primary" onClick={this.save}>{t('ok')}</Button>
				<Button onClick={this.cancel}>{t('cancel')}</Button>
			</div>
		</div>;
	}

}
