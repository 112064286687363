import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import store from 'client/store'
import t from "../../../../../../i18n";
import EditMaterial from './EditMaterial'
import AddIcon from '!!svg-react-loader!client/img/add-btn.svg';
import { Column, Table } from '@smartplatform/ui';
import { DeleteButton } from 'components';

@inject('store') @observer
export default class Materials extends React.Component {

	@observable materials = [];
	@observable selectedMaterial = null;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.order = this.props.store.order;
		this.init()
	}

	init = async () => {
	
		this.materials = await this.order.materials.find({
			include: [
				'workshop',
				{ relation: 'material', scope: { include: ['materialType'] } },
				{ relation: 'position', scope: { include: ['category'] } }]
		});
		this.isLoading = false;
	};

	addMaterial = async () => {
		const newMaterialLog = new store.model.MaterialLog();
		newMaterialLog.order = this.order;
		await newMaterialLog.save();
		const orderLog = new store.model.OrderLog();
		orderLog.orderId = this.order.id;
		orderLog.materialLogId = newMaterialLog.id;
		orderLog.updated = ['newMaterial'];
		await orderLog.save();
		this.selectedMaterial = newMaterialLog;
		await this.order.save();
	};

	deleteProcess = async (materialLog) => {
		await materialLog.delete();
		const orderLog = new store.model.OrderLog();
		orderLog.orderId = this.order.id;
		orderLog.updated = ['deletedMaterial'];
		await orderLog.save();
		await this.order.save();
		await this.init();
	};

	closeEditPopup = async () => {
		this.selectedMaterial = null;
		await this.init();
	};

	onMaterialClick = material => this.selectedMaterial = material;

	renderActions = rowData => {
		const { record } = rowData;
		return <DeleteButton onConfirm={() => this.deleteProcess(record)} />;
	};

	renderCategory = (position) => position.category && position.category.name;

	render() {
		if(this.isLoading) return null;
		return <div className="materials-section">
			<div className="form-field">
				<label>{t('material.plural')}: </label>
			</div>
			{(this.materials.length > 0) && <div className="materials-list" >
				<Table multiline rows={this.materials} onRowClick={this.onMaterialClick} >
					<Column relation='position' computed={this.renderCategory} label={t('material.position')} />
					<Column relation='workshop' property="name" label={t('material.workshop')} />
					<Column relation='material' property="name" label={t('material.title')} />
					<Column property="feature" label={t('material.feature')} />
					<Column property="format" label={t('material.format')} />
					<Column property="units" label={t('material.units')} />
					<Column property="copiesNumber" label={t('material.copiesNumber')} />
					<Column property="adjustmentsNumber" label={t('material.adjustmentsNumber')} />
					<Column property="totalNumber" label={t('material.totalNumber')} />
					<Column width={40} >{this.renderActions}</Column>
				</Table>
			</div>}
			<div className="add-row"><AddIcon onClick={this.addMaterial} className="add-btn">add</AddIcon></div>
			{this.selectedMaterial && <EditMaterial onClose={this.closeEditPopup} record={this.selectedMaterial} materials={this.materials} />}
		</div>
	}
};
