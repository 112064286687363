import { Field, Form, NumberInput, Popover, Popup, RecordSelect, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Positions from '../Positions';

@inject('store') @observer
export default class EditProcess extends React.Component {
	@observable materialLog = null;
	@observable position = null;
	@observable material = null;
	@observable workshop = null;
	@observable materialType = null;

	constructor(props) {
		super(props);
		this.materialLog = this.props.record
		this.init();
	};

	init = async () => {
		this.position = this.materialLog.position;
		this.material = this.materialLog.material;
		this.workshop = this.materialLog.workshop;
		this.materialType = this.materialLog.material && this.materialLog.material.materialType;
		console.log('m', this.materialType)
	};

	onClose = () => this.props.onClose();
	onPositionSelect = (position) => {
		if (this.popover) this.popover.close();
		this.position = position || null;
	};
	getPopoverInstance = (instance) => this.popover = instance;
	onWorkshopChange = (workshop) => {
		this.workshop = workshop || null;
		this.operation = null;
		this.equipment = null;
	};
	onMaterialChange = (material) => this.material = material || null;
	onMaterialTypeChange = (materialType) => {
		this.material = null;
		this.materialType = materialType || null;
	}

	beforeSave = () => {
		this.materialLog.workshop = this.workshop;
		this.materialLog.position = this.position;
		this.materialLog.material = this.material;
	};

	calcTotal = () => {
		this.materialLog.totalNumber = (parseInt(this.materialLog.copiesNumber || 0) + parseInt(this.materialLog.adjustmentsNumber || 0)) || null;
	};

	onCopiesNumberChange = (copiesNumber) => {
		this.materialLog.copiesNumber = copiesNumber;
		this.calcTotal();
	};

	onAdjustmentsNumberChange = (adjustmentsNumber) => {
		this.materialLog.adjustmentsNumber = adjustmentsNumber;
		this.calcTotal();
	};

	render() {
		if (!this.materialLog) return null;
		const popup = <Positions onSelect={this.onPositionSelect} />
		return <Popup width={600} onClose={this.onClose} header={t('material.title')}>
			<Popover
				trigger="click" position='bottom' className="popover" getInstance={this.getPopoverInstance}
				content={popup}>
				{this.position ?
					<span>{this.position.category && this.position.category.name || t('material.noPositionCategory')} </span> : t('material.selectPosition')}
			</Popover>
			<Form beforeSave={this.beforeSave} record={this.materialLog} stay onSave={this.onClose} noDelete>
				<div className="form-field">
					<label>{t('material.workshop')}</label>
					<RecordSelect model={store.model.ProcessWorkshop} value={this.workshop}
						onChange={this.onWorkshopChange}
						property='name' />
				</div>
				<Row>
					<div className="form-field">
						<label>{t('material.type.title')}</label>
						<RecordSelect model={store.model.MaterialType}
							value={this.materialType}
							onChange={this.onMaterialTypeChange}
							property='name'
						/>
					</div>
					<div className="form-field">
						<label>{t('material.title')}</label>
						<RecordSelect model={store.model.Material}
							value={this.material}
							onChange={this.onMaterialChange}
							filter={{ where: { materialTypeId: this.materialType ? this.materialType.id : undefined } }}
							property='name'
						/>
					</div>
				</Row>
				<Row>
					<Field property="feature" label={t('material.feature')} />
					<Field property="format" label={t('material.format')} />
					<Field property="units" label={t('material.units')} />
				</Row>
				<Row>
					<Field property="copiesNumber" label={t('material.copiesNumber')}>
						<NumberInput onChange={this.onCopiesNumberChange} positiveOnly integerOnly /></Field>
					<Field property="adjustmentsNumber" label={t('material.adjustmentsNumber')}>
						<NumberInput onChange={this.onAdjustmentsNumberChange} positiveOnly integerOnly /></Field>
					<Field property="totalNumber" label={t('material.totalNumber')}>
						<NumberInput positiveOnly integerOnly /></Field>
				</Row>
			</Form>
		</Popup>;
	}

}
