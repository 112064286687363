import { Field, Form, NumberInput, Popover, Popup, RecordSelect, Row } from '@smartplatform/ui';
import store from 'client/store';
import t from 'i18n';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Positions from '../Positions';

@inject('store') @observer
export default class EditProcess extends React.Component {
	@observable process = null;
	@observable position = null;
	@observable equipment = null;
	@observable operation = null;
	@observable workshop = null;
	@observable targetWorkshop = null;

	constructor(props) {
		super(props);
		this.process = this.props.record
		this.init();
	}

	init = async () => {
		this.position = this.process.position;
		this.equipment = this.process.equipment;
		this.operation = this.process.operation;
		this.workshop = this.process.workshop;
		this.targetWorkshop = this.process.targetWorkshop;
	}

	onClose = () => this.props.onClose();
	onPositionSelect = (position) => {
		if (this.popover) this.popover.close();
		this.position = position || null;
	};
	getPopoverInstance = (instance) => this.popover = instance;
	onEquipmentChange = (equipment) => this.equipment = equipment || null;
	onOperationChange = (operation) => this.operation = operation || null;
	onTargetWorkshopChange = (targetWorkshop) => this.targetWorkshop = targetWorkshop || null;

	onWorkshopChange = (workshop) => {
		this.workshop = workshop || null;
		this.operation = null;
		this.equipment = null;
	};

	beforeSave = () => {
		this.process.workshop = this.workshop;
		this.process.targetWorkshop = this.targetWorkshop;
		this.process.position = this.position;
		this.process.operation = this.operation;
		this.process.equipment = this.equipment;
	};

	render() {
		if (!this.process) return null;
		const popup = <Positions onSelect={this.onPositionSelect} />
		return <Popup width={600} onClose={this.onClose} header={t('process.title')}>
			<Popover
				trigger="click" position='bottom' className="popover" getInstance={this.getPopoverInstance}
				content={popup}>
				{this.position ?
					<span>{this.position.category && this.position.category.name || t('process.noPositionCategory')} </span> : t('process.selectPosition')}
			</Popover>
			<Form beforeSave={this.beforeSave} record={this.process} stay onSave={this.onClose} noDelete>
				<Row>
					<div className="form-field">
						<label>{t('process.workshop.title')}</label>
						<RecordSelect model={store.model.ProcessWorkshop} value={this.workshop}
							onChange={this.onWorkshopChange}
							filter={{ where: { id: { neq: this.targetWorkshop && this.targetWorkshop.id } } }}
							property='name' />
					</div>
					<div className="form-field">
						<label>{t('process.targetWorkshop')}</label>
						<RecordSelect model={store.model.ProcessWorkshop}
							value={this.targetWorkshop}
							onChange={this.onTargetWorkshopChange}
							filter={{ where: { id: { neq: this.workshop && this.workshop.id } } }}
							property='name' />
					</div>
				</Row>
				<Row>
					<div className="form-field">
						<label>{t('process.operation.title')}</label>
						<RecordSelect model={store.model.ProcessOperation}
							value={this.operation}
							disabled={!this.workshop}
							onChange={this.onOperationChange}
							property='name'
							filter={{ where: { workshopId: this.workshop && this.workshop.id } }} />
					</div>
					<div className="form-field">
						<label>{t('process.equipment')}</label>
						<RecordSelect model={store.model.ProcessEquipment}
							value={this.equipment}
							disabled={!this.workshop}
							onChange={this.onEquipmentChange}
							property='name'
							filter={{ where: { workshopId: this.workshop && this.workshop.id } }} />
					</div>
				</Row>
				<Row>
					<Field property="adjustmentsNumber" label={t('process.adjustmentsNumber')}>
						<NumberInput positiveOnly integerOnly /></Field>
					<Field property="operatorsNumber" label={t('process.operatorsNumber')}>
						<NumberInput positiveOnly integerOnly /></Field>
					<Field property="workingTime" label={t('process.workingTime')}>
						<NumberInput positiveOnly integerOnly /></Field>
				</Row>
			</Form>
		</Popup>;
	}

}
