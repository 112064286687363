import { observable } from 'mobx';

import store from 'client/store';
import t from 'i18n';

export const ORDERS_LIMIT = 10;
export default class OrderStore {

	@observable order = null;
	@observable canViewSum = false;

	constructor() {
	}

	init = async (id) => {
		if (!id) {
			this.order = new store.model.Order();
			await this.order.save();
		}
		else {
			if (!parseInt(id)) {
				throw new Error(t('invalidId') + ': ' + id);
			}
			else {
				this.order = await store.model.Order.findById(id, {
					include: [
						{ relation: 'list', scope: { fields: ['id', 'name', 'color'] } },
						{ relation: 'client', scope: { include: { relation: 'orders', scope: { order: 'id desc', limit: ORDERS_LIMIT } } } },
						{ relation: 'manager', scope: { order: 'lastName asc, firstName asc, middleName asc ' } },
						{ relation: 'positions', scope: { order: 'id asc' } },
					]
				});
			}
		}
	};

	save = async () => {
		await this.order.save();
		await this.updateHistory();
	};

	updateHistory = async () => {
		if (this.historyInstance) this.historyInstance.reload();
	};

}
