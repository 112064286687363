import React, { useEffect, useState } from "react";
import store from 'client/store';
import { ModelList } from 'components';
import { Column } from '@smartplatform/ui';

const TransferDirection = props => {
    const { relation } = props;
    if(relation.sender) {
        return "Отправка";
    } else if(relation.reciver) {
        return "Получение";
    } else {
        return "Не определено";
    }
}

const List = ({ modelName, path }) => {
    return (
        <ModelList disableFilters model={store.model[modelName]} path={path} title="История синхронизаций">
            <Column property="updatedAt" label="Дата создания" width={100}/>
            <Column relation="edNode" property="secondBaseName" label="Имя узла обмена" width={250}/>
            <Column property="uuid" label="Идентефикатор файла"/>
            <Column relation="edTransfer" property="uuid" label="Идентефикатор трансфера" />
            <Column relation="edTransfer" label="Направление">{rowData => <TransferDirection {...rowData}/>}</Column>
        </ModelList>
    );
}

export default List;